import { Component, Input, OnChanges } from '@angular/core';
import {
  OrderDetails,
  ORDER_STATUS,
  ORDER_TYPE,
} from '../../../orders/order.interface';

@Component({
  selector: 'mvta-order-detail-details',
  templateUrl: './order-details.component.html',
})
export class OrderDetailDetailsComponent implements OnChanges {
  ORDER_STATUS = ORDER_STATUS;
  ORDER_TYPE = ORDER_TYPE;
  orderStatus;

  @Input() order: OrderDetails;
  @Input() print: boolean = false;

  constructor() {
    this.orderStatus = {};
    this.orderStatus[ORDER_STATUS.WITH_REFUNDS] = {
      title: 'Con Devoluciones',
      classes: 'badge badge-danger',
    };
    this.orderStatus[ORDER_STATUS.PENDING_PAYMENT] = {
      title: 'Pendiente de Pago',
      classes: 'badge badge-warning',
    };
    this.orderStatus[ORDER_STATUS.EXPIRED] = {
      title: 'Expirada',
      classes: 'badge badge-warning',
    };
    this.orderStatus[ORDER_STATUS.COMPLETED] = {
      title: 'Completada',
      classes: 'badge badge-success',
    };
    this.orderStatus[ORDER_STATUS.PENDING_APPROVAL] = {
      title: 'Pendiente de Aprovación',
      classes: 'badge badge-info',
    };
    this.orderStatus[ORDER_STATUS.CONV_STORE] = {
      title: 'Tienda de Conveniencia',
      classes: 'badge badge-info',
    };
    this.orderStatus[ORDER_STATUS.PREAUTHORIZED] = {
      title: 'Pre Autorizada',
      classes: 'badge badge-info',
    };
    this.orderStatus[ORDER_STATUS.REJECTED] = {
      title: 'Rechazada',
      classes: 'badge badge-danger',
    };
  }

  ngOnChanges() {}
}
