export interface Block {
  type: string;
  name: string;
  order: number;
  styles: Array<Style>;
}

export interface Style {
  active: boolean;
  name: string;
  settings: Settings;
  style: string;
}

export interface Settings {
  sections: Array<Section>;
}

export interface Section {
  type: string;
  title: string;
  text?: string;
  list?: Array<Link>;
}

export interface Link {
  text: string;
  text2?: string;
  url: string;
  linkType: 'facebook' | 'instagram' | 'twitter' | 'email' | 'internal' | 'phone' | 'file' | 'products';
  queryParams?: {
    [key: string]: string | string[];
  }
  show?: boolean;
}

export interface LinkType {
  name: string;
  iconClass: string;
  label: string;
};

export interface ContactLinkContent {
  title: string;
  contentType: 'text' | 'link';
  content: string;
  icon: string;
  url?: string;
  linkType?: 'phone' | 'email'
}

export const LinkTypes: Array<LinkType> = [
  {
    name: 'internal',
    iconClass: 'fa fa-anchor',
    label: 'Enlace Interno'
  },
  {
    name: 'external',
    iconClass: 'fa fa-external-link-alt',
    label: 'Enlace Externo'
  },
  {
    name: 'ancla',
    iconClass: 'fas fa-arrows-alt',
    label: 'Ancla'
  },
  {
    name: 'phone',
    iconClass: 'fa fa-phone',
    label: 'Teléfono'
  },
  {
    name: 'email',
    iconClass: 'fa fa-envelope',
    label: 'E-Mail'
  },
  {
    name: 'facebook',
    iconClass: 'fa fa-facebook-square',
    label: 'Facebook'
  },
  {
    name: 'instagram',
    iconClass: 'fa fa-instagram',
    label: 'Instagram'
  },
  {
    name: 'twitter',
    iconClass: 'fa fa-twitter',
    label: 'Twitter'
  },
  {
    name: 'file',
    iconClass: 'fas fa-file-pdf',
    label: 'Archivo'
  },
  {
    name: 'products',
    iconClass: 'fas fa-tags',
    label: 'Listado de productos'
  },
];
