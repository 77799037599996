import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Zone, ZoneCountry } from 'app/logistics/logistics_zone.interface';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth';
import { ShipmentRate } from './shipment_rate.interface';
import { Supplier } from './supplier.interface';





@Injectable()
export class LogisticsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  // ------ Supplier ------
  getSupplier(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/supplier');
  }

  updateSupplier(addr: Supplier): Observable<any> {
    delete addr['createdAt'];
    delete addr['updatedAt'];

    return this.http.put(environment.apiUrl + '/api/v1/admin/logistic/supplier', addr);
  }

  // ------ Logistics ------
  getLogisticsSettings(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistics/settings');
  }

  updateLogisticsSettings(data): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/v1/admin/logistics/settings', data);
  }

  // ------ Zones ------
  getZones(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/zones');
  }

  getZone(zoneId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/zone/' + zoneId);
  }

  getCountriesForZone(zoneId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/zone_countries/' + zoneId);
  }

  getAvailableCountries(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/countries');
  }

  updateZoneCountries(zoneId: string, countries: Array<ZoneCountry>): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/api/v1/admin/logistic/zone_countries',
      {
        zoneId: zoneId,
        countries: countries
      }
    );
  }

  updateZone(zone: Zone): Observable<any> {
    delete zone['createdAt'];
    delete zone['updatedAt'];
    return this.http.put(environment.apiUrl + '/api/v1/admin/logistic/zone', zone);
  }

  insertZone(zone: Zone): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/logistic/zone', zone);
  }

  deleteZone(zoneId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/api/v1/admin/logistic/zone/' + zoneId);
  }

  getShippingCountries(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/logistics/countries');
  }

  getAddresses(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/addresses');
  }

  getRates(shipmentData: ShipmentRate): Observable<any> {
    shipmentData.from = this.removeAtKeys(shipmentData.from);
    shipmentData.to = this.removeAtKeys(shipmentData.to);

    return this.http.post(environment.apiUrl + '/api/v1/admin/logistic/shipment-rates', shipmentData);
  }

  createShipment(shipmentData: ShipmentRate): Observable<any> {
    shipmentData.from = this.removeAtKeys(shipmentData.from);
    shipmentData.to = this.removeAtKeys(shipmentData.to);

    return this.http.post(environment.apiUrl + '/api/v1/admin/logistic/shipment', shipmentData);
  }
  createShipmentEnvioClickPro(shipmentData: ShipmentRate): Observable<any> {
    shipmentData.from = this.removeAtKeys(shipmentData.from);
    shipmentData.to = this.removeAtKeys(shipmentData.to);

    return this.http.post(environment.apiUrl + '/api/v1/admin/logistic/shipment/envioclickpro', shipmentData);
  }

  payShipmentRate(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/logistic/shipment/pay', data);
  }

  getShipments(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/shipments');
  }

  syncShipment(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistic/shipment/sync/' + id);
  }

  // ---- Shipping location

  getShippingLocations(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/logistic/shipping-locations`);
  }

  addShippingLocation(location): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/logistic/shipping-location`, location);
  }

  updateShippingLocation(location): Observable<any> {
    delete location.createdAt;
    delete location.updatedAt;

    return this.http.put(`${environment.apiUrl}/api/v1/admin/logistic/shipping-location`, location);
  }

  deleteShippingLocation(lat, lng): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        lat: String(lat),
        lng: String(lng)
      }
    })
    return this.http.delete(`${environment.apiUrl}/api/v1/admin/logistic/shipping-location`, { params: qParams });
  }

  removeAtKeys(addr) {
    const addrC = Object.assign({}, addr);
    if (addrC.updatedAt !== undefined) {
      delete addrC.updatedAt;
      delete addrC.createdAt;
    }
    if (addrC.shippingMethod !== undefined && addrC.shippingMethod.warehouseLocation) {
      delete addrC.shippingMethod.warehouseLocation.address.createdAt;
      delete addrC.shippingMethod.warehouseLocation.address.updatedAt;
    }

    return addrC;
  }
}
