import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductCustomField } from 'app/products/custom-fields/custom-field.interface';
import { ProductsService } from 'app/products/products.service';
import { Link, LinkTypes } from 'app/website/blocks/layout.interface';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, map, share, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { debounce } from '../elements/debounce';

@Component({
  selector: 'mvta-link-editor',
  templateUrl: 'link-editor.component.html',
  providers: [ProductsService]
})

export class LinkEditorComponent implements OnInit, AfterViewInit {
  linkTypes = LinkTypes;

  @Input() showTitle = true;
  @Input() link: Link;
  @Output() linkChange = new EventEmitter<Link>();

  linkType$ = new ReplaySubject<string>();
  addField$ = new Subject<string>();
  editField$ = new Subject<{ id: string; value: any; }>();
  deleteField$ = new Subject<string>();
  category$ = new BehaviorSubject<string>('');
  selectedCustomFields$ = new Subject<any[]>();
  data$: Observable<any>;
  customFields$: Observable<any>;

  currentCustomField: string;
  constructor(private productsService: ProductsService) { }

  ngOnInit() {
    if (!this.link) {
      this.link = {
        linkType: 'phone',
        text: '',
        url: '',
        queryParams: {},
        show: true
      };

      this.linkChange.emit(this.link);
    }
  }

  ngAfterViewInit() {
    this.linkType$.next(this.link.linkType);
    const availableFields$ = this.linkType$.pipe(
      filter(linkType => linkType === 'products'),
      switchMap(() => this.productsService.getAvailableCustomFields()),
      share(),
    );

    const initialSelectedFields$ = availableFields$.pipe(
      map(availableFields => {
        if (this.link.queryParams && this.link.queryParams.category && typeof this.link.queryParams.category === 'string') {
          this.category$.next(this.link.queryParams.category);
        }
        return this.link.queryParams && this.link.queryParams.orCustomFields && Array.isArray(this.link.queryParams.orCustomFields) ?
          this.link.queryParams.orCustomFields.map(field => {
            const fieldPair = field.split(':');
            if (fieldPair.length === 2) {
              const foundField = availableFields.find(availableField => availableField.id === fieldPair[0]);
              return foundField ? {
                id: fieldPair[0],
                value: fieldPair[1],
                fieldType: foundField.fieldType,
                name: foundField.name,
              } : null;
            }
            return null;
          }).filter(Boolean) : [];
      }),
      tap(selectedFields => this.selectedCustomFields$.next(selectedFields)),
    );

    const addField$ = this.addField$.pipe(
      withLatestFrom(this.selectedCustomFields$),
      withLatestFrom(availableFields$),
      map(([[addField, selectedFields], availableFields]) => {
        const selectedField = selectedFields.find(field => field.id === addField);
        if (!selectedField) {
          const availableField = availableFields.find(f => f.id === addField);
          if (availableField) {
            selectedFields.push(availableField);
          }
        }
        return selectedFields;
      })
    );
    const editFieldValue$ = this.editField$.pipe(
      debounceTime(450),
      withLatestFrom(this.selectedCustomFields$),
      map(([editField, selectedFields]) => {
        const selectedFieldIndex = selectedFields.findIndex(field => field.id === editField.id);
        if (selectedFieldIndex > -1) {
          selectedFields[selectedFieldIndex].value = editField.value;
        }
        return selectedFields;
      })
    );
    const deleteField$ = this.deleteField$.pipe(
      withLatestFrom(this.selectedCustomFields$),
      map(([removeField, selectedFields]) => {
        const selectedFieldIndex = selectedFields.findIndex(field => field.id === removeField);
        if (selectedFieldIndex > -1) {
          selectedFields.splice(selectedFieldIndex, 1);
        }
        return selectedFields;
      })
    );

    const selectedFields$ = merge(
      initialSelectedFields$,
      addField$,
      editFieldValue$,
      deleteField$,
    );

    this.data$ = combineLatest([
      availableFields$,
      selectedFields$,
      this.category$.pipe(debounceTime(500)),
    ]).pipe(
      tap(([_, selectedFields, category]) => {
        const orCustomFields = selectedFields.map(field => {
          if (field.fieldType === 'bool') {
            return field.id + ':true';
          } else {
            return `${field.id}:${field.value}`;
          }
        });

        this.link.queryParams = {
          category: category || '',
          orCustomFields
        };
        this.emitChanges();
      }),
      map(([availableFields, selectedFields, category]) => ({ availableFields, selectedFields, category })),
    );
  }

  emitChanges() {
    this.linkChange.emit(this.link);
  }

  changeLinkType(linkType: 'facebook' | 'instagram' | 'twitter' | 'email' | 'internal' | 'phone' | 'file' | 'products') {
    this.link.linkType = linkType;
    this.linkType$.next(linkType);
    this.emitChanges();
  }
}
