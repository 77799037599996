import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { DateRange } from '../date-range/date-range.component';
import { DateAdapter } from '../date-range/dateAdapter.service';

@Component({
  selector: 'mvta-date-range-hidden',
  templateUrl: 'date-range-hidden.component.html',
  styleUrls: ['date-range-hidden.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: DateAdapter },
  ],
})

export class DateRangeHiddenComponent implements OnInit, OnChanges, OnDestroy {
  from: NgbDate;
  to: NgbDate;

  hoveredDate: NgbDate | null;

  sub: Subscription;

  @ViewChild('datepicker') datePicker: NgbInputDatepicker;

  @Input() date: DateRange | null;
  @Input() open: Observable<void>;
  @Output() dateRange: EventEmitter<DateRange> = new EventEmitter();

  constructor(
    private dateAdapter: NgbDateAdapter<string>,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter) {}

  ngOnInit() {
    if (!this.date || !this.date.from|| !this.date.to) {
      this.from = this.calendar.getToday();
      this.to = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    }

    if (this.open) {
      this.sub = this.open.subscribe(() => {
        this.datePicker.isOpen() ? this.datePicker.close() : this.datePicker.open();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.from = NgbDate.from(this.dateAdapter.fromModel(changes.date.currentValue.from));
      this.to = NgbDate.from(this.dateAdapter.fromModel(changes.date.currentValue.to));
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  onDateSelection(date: NgbDate): void {
    if (!this.from && !this.to) {
      this.from = date;
    } else if (
      this.from &&
      !this.to &&
      date &&
      (date.after(this.from) || date.equals(this.from))
    ) {
      this.to = date;
      this.dateRange.emit({
        from: this.dateAdapter.toModel(this.from),
        to: this.dateAdapter.toModel(this.to),
      });
    } else {
      this.to = null;
      this.from = date;
    }
  }

  isHovered(date: NgbDate): boolean {
    return (
      this.from &&
      !this.to &&
      this.hoveredDate &&
      date.after(this.from) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean {
    return this.to && date.after(this.from) && date.before(this.to);
  }

  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.from) ||
      (this.to && date.equals(this.to)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
}
