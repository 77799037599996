import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class IconPickerService {
    constructor(private http: HttpClient) { }

    getImages(): Observable<any> {
      const params = new HttpParams({
        fromObject: {
          type: 'icon'
        }
      });
      return this.http.get(`${environment.apiUrl}/api/v1/admin/images`, { params });
    }

    uploadImage(imageData: string): Observable<any> {
        const formData = new FormData();
        formData.append('image', imageData);
        formData.append('type', 'icon');

        return this.http.post(environment.apiUrl + '/api/v1/admin/image', formData);
    }

    deleteImage(image: string) {
      const params = new HttpParams({
        fromObject: {
          name: image,
          type: 'icon'
        }
      })
      return this.http.delete(environment.apiUrl + '/api/v1/admin/image', { params });
    }

}
