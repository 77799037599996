import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DuePaymentService } from 'app/shared/due-payment/due-payment.service';
import { DuePaymentState } from 'app/shared/due-payment/due-payment.state';
import { TidioService } from 'app/shared/tidio.service';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { versions } from '../../../environments/versions';
import { LogisticsService } from '../../logistics/logistics.service';
import { AccountService } from '../../shared/auth/account.service';
import { AuthService } from '../../shared/auth/auth.service';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AccountsService } from './../../account/account.service';

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: 'mvta-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  providers: [AccountsService, LogisticsService, CurrencyPipe, DatePipe],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideOnOff', [
      state('on', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('off', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('on => off', animate('400ms ease-in-out')),
      transition('off => on', animate('400ms ease-in-out'))
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})

export class AdminLayoutComponent implements OnInit, OnDestroy {
  deviceType = 'desktop';
  verticalNavType = 'expanded';
  verticalEffect = 'shrink';
  chatToggle = 'out';
  chatInnerToggle = 'off';
  innerHeight: string;
  isScrolled = false;
  isCollapsedMobile = 'no-block';
  toggleOn = true;
  windowWidth: number;
  fullName = '';
  customerEmail = '';
  businessName = '';

  urgentActions = [];
  activeActions = [];
  currentActionDisplay = 0;
  menuElements = [];
  tokenChangeObservable;

  // Due Section
  activeBlock = false;
  blockMessage = '';
  defaultBlockMessage = 'Estimado cliente tu cuenta presenta un adeudo pendiente.';
  dueAndCycle;
  resolveAction;
  public showDuePaymentModal = new EventEmitter();
  duePaymentModal

  version;

  @ViewChild('searchFriends') search_friends: ElementRef;
  @ViewChild('toggleButton') toggle_button: ElementRef;
  @ViewChild('sideMenu') side_menu: ElementRef;
  constructor(public menuItems: MenuItems, private authService: AuthService, private accountService: AccountService, private router: Router,
    private accountsService: AccountsService, private logisticsService: LogisticsService, private duePaymentService: DuePaymentService,
    private duePaymentState: DuePaymentState, private tidioService: TidioService) {

    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);
    this.version = versions;

    this.urgentActions = [
      {
        id: 'paymentGateways',
        text: `Aún no has configurado ningún procesador de pagos.
                Si no configuras al menos uno, no podrás realizar ventas.`,
        active: false,
        checkFn: 'getPaymentGateways',
        actionUrl: '/cuenta/cobros'
      }, {
        id: 'logistics',
        text: `No existen zonas y tarifas de envío.
                Si no creas al menos una, no podrás realizar ventas.`,
        active: false,
        checkFn: 'checkLogisticsRegions',
        actionUrl: '/logistica/configuracion'
      }, {
        id: 'accountInfo',
        text: `Completa la información de tu cuenta.`,
        active: false,
        checkFn: 'checkAccountInfo',
        actionUrl: '/cuenta/informacion'
      }
    ];

    this.resolveAction = (ev) => {
      if (ev && ev.preventDefault) {
        ev.preventDefault();
      }
      this.router.navigate(['/cuenta/planes-pagos/recibos']);
    };
  }

  ngOnInit() {
    this.fullName = this.authService.tokenDetails().name.trim();
    this.customerEmail = this.authService.tokenDetails().email;

    this.tokenChangeObservable = this.accountService.tokenChange.subscribe(() => this.tokenChanges());

    observableForkJoin(this.urgentActions.map((u) => this[u.checkFn]()))
      .subscribe((response) => {
        response.forEach((r, idx) => {
          this.urgentActions[idx].active = (r === false);
        });

        this.activeActions = [].concat(this.urgentActions.filter((u) => u.active).map((u) => Object.assign({}, u)));
        this.currentActionDisplay = 0;
      });
  }

  ngOnDestroy() {
    this.tokenChangeObservable.unsubscribe();
  }

  tokenChanges() {
    if (!this.accountService.getToken()) {
      return;
    }
    this.businessName = this.accountService.tokenDetails().name.trim();
    this.duePaymentState.setDueAndCycle(null);
    const overdue = this.accountService.tokenDetails().overdue || 0;
    if (overdue > 0) {
      this.getAccountDueAndCycle();
    }
    setTimeout(() => {
      this.menuElements = this.menuItems.getAll();
    }, 100);
  }

  onHiddeAction(idx) {
    this.activeActions.splice(idx, 1);
    this.currentActionDisplay = 0;
  }

  getPaymentGateways(): Observable<any> {
    return Observable.create((observer) => {
      this.accountsService.getPaymentGatewaysConfig()
        .subscribe((response) => {
          const config = response || {};
          observer.next(!!((config.paypal && config.paypal.apiKey && config.paypal.txKey) || config.openpay || config.mercadopago))
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
    });
  }

  checkLogisticsRegions() {
    return Observable.create((observer) => {
      this.logisticsService.getShippingCountries()
        .subscribe((response) => {
          observer.next((response || []).some((c) => c.regions.length > 0));
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
    });
  }

  checkAccountInfo() {
    return Observable.create((observer) => {
      this.accountsService.getInfo(this.accountService.tokenDetails().aud)
        .subscribe((response) => {
          observer.next(!!(response.contactName && response.contactName.length > 0 && response.contactName !== 'na'));
          observer.complete();
        }, (error) => {
          observer.error(error);
        });
    });
  }

  getAccountDueAndCycle() {
    this.accountsService.dueAndCycle()
      .subscribe((response) => {
        this.dueAndCycle = response;
        if (this.dueAndCycle.due === 0) {
          if (window.location.href.indexOf('reload') > -1) {
            return;
          }
          // Nueva selección de plan y pago con recurrencia (se pagó via suscripción)
          this.accountsService.getAccessToken( this.accountService.tokenDetails().aud)
            .subscribe((tokenResponse) => {
              this.accountService.save(tokenResponse.jwt);
              setTimeout(() => {
                window.location.href = '/?reload=true';
              }, 500);
            });

          return;
        }
        this.dueAndCycle.dueBeforeTax = Math.round((response.due / 1.16) * 100.0) / 100.0;
        this.dueAndCycle.tax = this.dueAndCycle.due - this.dueAndCycle.dueBeforeTax;
        this.duePaymentState.setDueAndCycle(this.dueAndCycle);

        this.resolveAction = (ev) => {
          if (ev && ev.preventDefault) {
            ev.preventDefault();
          }
          this.duePaymentService.show();
        };
        this.duePaymentService.showAlert();

        this.blockMessage =  this.duePaymentState.getMessage();
        this.activeBlock = true;
      }, () => {
        this.dueAndCycle = {};
        this.activeBlock = true;
        this.blockMessage = this.defaultBlockMessage;
      });
  }

  onClickedOutside(e: Event) {
    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  onResize(event) {
    const height = this.innerHeight || '0px';
    const heightDifference = Math.abs(Number(height.replace('px', '')) - event.target.innerHeight);

    this.innerHeight = event.target.innerHeight + 'px';
    this.windowWidth = event.target.innerWidth;

    // Check for browser nav hide /show in mobiles
    if (heightDifference > 56 || event.target.innerWidth > 1024) {
      this.setMenuAttributs(this.windowWidth);
    }
  }

  setMenuAttributs(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'collapsed';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  searchFriendList(event) {
    const search = (this.search_friends.nativeElement.value).toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll('.userlist-box .media-body .chat-header');
    Array.prototype.forEach.call(friendList, function (elements, index) {
      search_input = (elements.innerHTML).toLowerCase();
      search_parent = (elements.parentNode).parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === 'out' ? 'in' : 'out';
  }

  toggleChatInner() {
    this.chatInnerToggle = this.chatInnerToggle === 'off' ? 'on' : 'off';
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
    } else {
      this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
    }
  }
  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  onScroll(event) {
    this.isScrolled = false;
  }

  onLogout(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    this.authService.logout();
  }
}
