import { LyTheme2 } from '@alyle/ui';
import { ImgCropperConfig, ImgOutput, ImgResolution, LyResizingCroppingImages } from '@alyle/ui/resizing-cropping-images';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import swal from 'sweetalert2';

const styles = {
  actions: {
    display: 'flex',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  cropping: {
    maxWidth: '500px',
    height: '500px',
    border: '1px dashed #ccc',
    background: '#eee'
  },
  flex: {
    flex: 1
  }
};

const popUpStyles = {
  actions: {
    display: 'flex',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  cropping: {
    maxWidth: '145px',
    height: '90px',
    border: '1px solid #ccc',
    background: '#eee',
    margin: '0 auto'
  },
  flex: {
    flex: 1
  }
};

@Component({
  selector: 'mvta-img-cropper',
  templateUrl: './img-cropper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnInit, OnChanges {
  @ViewChild(LyResizingCroppingImages) cropper: LyResizingCroppingImages;

  @Input() outputSize;
  @Input() popUpStyles = false;
  @Input() label;
  @Input() cropperStyles
  @Input() showCancelButton = true;
  @Input() showSelectImageButton = true;
  @Output() cropped = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() imageSelected = new EventEmitter();

  imageType;

  classes = this.theme.addStyleSheet(styles);
  croppedImage?: string;
  result: string;
  canvasBaseSize = 420;
  config: ImgCropperConfig = {
    width: this.canvasBaseSize,
    height: this.canvasBaseSize,
    output: {
      width: 700,
      height: 700
    },
    // extraZoomOut: true,
    fill: null
  };
  outputSizeSetted;

  constructor(private theme: LyTheme2) { }

  ngOnInit(): void {
    this.classes = this.popUpStyles
      ? this.theme.addStyleSheet(popUpStyles)
      : this.theme.addStyleSheet(styles)

    if(this.popUpStyles) {
      this.config.width = 80;
      this.config.height = 80;
      this.config.output = 80;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.outputSize !== undefined) {
      const output = changes.outputSize.currentValue;
      const imgOutput: ImgOutput = {
        width: output.width,
        height: output.height
      };
      this.config.output = imgOutput;
      this.outputSizeSetted = { ...imgOutput };

      // Mantenemos el aspect radio de las dimensiones requeridas
      // El tamaño base del canvas es 420px x 420px, mantenemos este
      // tamaño para la parte mas grande de las dimensiones requeridas
      // y calculamos el tamaño del otro lado manteniendo las proporciones (el aspect ratio)
      if (this.config.output.height > this.config.output.width) {
        const aspectRadio = this.config.output.height / this.config.output.width;
        this.config.height = this.canvasBaseSize;
        this.config.width = Math.floor(this.config.height / aspectRadio);
      } else {
        const aspectRadio = this.config.output.width / this.config.output.height;
        this.config.width = this.canvasBaseSize;
        this.config.height = Math.floor(this.config.width / aspectRadio);
      }
    }
  }

  onCropped(e) {
    if (this.imageType === 'image/png' || this.imageType === 'image/svg+xml') {
      this.resizeImage(e.dataURL)
        .then((img) => {
          this.croppedImage = img;
          this.cropped.emit(this.croppedImage);
          this.cropper.clean();
        });
    } else {
      this.croppedImage = e.dataURL;
      this.cropped.emit(this.croppedImage);
      this.cropper.clean();
    }
  }

  onCancel() {
    this.cancel.emit(true);
    this.cropper.clean();
  }

  onError(ev) {
    swal({
      type: 'error',
      title: 'Formato de imagen no soportado',
      html: '<h4>El formato de la imagen proporcionada no es soportado. Se permiten imágenes con los siguientes formatos: <strong>JPG, PNG, GIF y SVG</strong></h4>', /* <h5>Error:  ' + ev.name + '</h5> */
    });
  }

  onLoaded(ev) {
    this.imageType = ev.type;
    if (this.imageType === undefined) {
      this.imageType = this.getType(ev.originalDataURL);
    }
    this.config.type = this.imageType;

    if (this.imageType === 'image/jpg' || this.imageType === 'image/jpeg') {
      this.config.fill = '#FFFFFF';
      this.config = Object.assign({}, this.config);
    }

    if (this.imageType === 'image/png' || this.imageType === 'image/svg+xml') {
      this.config.output = ImgResolution.OriginalImage;
      this.config = Object.assign({}, this.config);
    }
  }

  resizeImage(dataURL): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const oc = document.createElement('canvas');
        const octx = oc.getContext('2d');

        oc.width = img.width;
        oc.height = img.height;
        octx.drawImage(img, 0, 0, oc.width, oc.height);

        const finalCanvas = this.imageSmoothingQuality(oc, this.outputSize, 0.5);
        resolve(finalCanvas.toDataURL(this.imageType));
      };
      img.onerror = () => {
        resolve(dataURL);
      };
      img.src = dataURL;
    });
  }

  getType(base64): string {
    const parts = base64.split(';');
    const mimeType = parts[0].split(':')[1];
    return mimeType;
  }

  imageSmoothingQuality(img: HTMLCanvasElement, config, quality: number): HTMLCanvasElement {
    /** Calculate total number of steps needed */
    let numSteps = Math.ceil(Math.log(Math.max(img.width, img.height) / Math.max(config.width, config.height)) / Math.log(2)) - 1;
    numSteps = numSteps <= 0 ? 0 : numSteps;

    /**Array steps */
    const steps = Array.from(Array(numSteps).keys());

    /** Context */
    const octx = img.getContext('2d')!;

    const q = ((quality * 10) ** numSteps) / (10 ** numSteps);
    const fileType = this.imageType;

    /** Only the new img is shown. */
    if (fileType === 'image/png' || fileType === 'image/svg+xml') {
      octx.globalCompositeOperation = 'copy';
    }

    /** If Steps => imageSmoothingQuality */
    if (numSteps) {
      /** Set size */
      const w = img.width * quality;
      const h = img.height * quality;

      /** Steps */
      (steps as Array<number>).forEach(() => {
        octx.drawImage(img,
          0, 0,
          w, h
        );
      });
    }

    /**
     * Step final
     * Resizing & cropping image
     */
    const oc = document.createElement('canvas'),
      ctx = oc.getContext('2d')!;
    oc.width = config.width;
    oc.height = config.height;
    ctx.drawImage(img,
      0, 0,
      img.width * q, img.height * q,
      0, 0,
      oc.width, oc.height
    );
    return oc;
  }

  imageLoaded(ev) {
    this.imageSelected.emit({ ...ev })
  }
}
