import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findInArray'
})

export class FindInArrayPipe implements PipeTransform {
  transform(value: any, array: Array<any>): boolean {
    return array.some(a => a === value);
  }
}
