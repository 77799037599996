import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ImagePickerService } from '../image-picker/image-picker.service';

@Component({
  selector: 'mvta-image-picker',
  templateUrl: 'image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  providers: [ImagePickerService]
})
export class ImagePickerComponent implements OnInit {

  @Input() src: string;
  @Output() srcChange = new EventEmitter();
  @Output() modeChange = new EventEmitter();

  @Input() showRemove = true;
  @Input() showChange = true;
  @Input() showInstructions = true;
  @Input() changeText = 'Cambiar';
  @Input() removeText = 'Quitar';
  @Input() cancelSelectionText = 'Cancelar';

  @Input() imagesSize = {
    width: 400,
    height: 400
  };

  MAX_IMAGE = {
    WIDTH: 1920,
    HEIGHT: 1080
  }; // fHD
  selectionMode = false;
  uploading = false;
  changed = false;
  loadingImages = false;
  images = [];
  imagesEdit = {
    show: false,
    type: ''
  };

  constructor(private imagePickerService: ImagePickerService, private toastrService: ToastrService, private modalService: NgbModal) { }

  ngOnInit() {

  }

  onDeleteImage() {
    this.src = '';
    this.srcChange.emit(this.src);
  }

  onChangeImage() {
    this.srcChange.emit(this.src);
    this.setSelectionMode(true);
    if (this.images.length === 0) {
      this.loadingImages = true;
      this.imagePickerService.getImages()
        .subscribe((response) => {
          this.images = (response.images || [])
            .map((i) => {
              return {
                src: i.link
              };
            });
          this.loadingImages = false;
        }, (error) => {
          this.images = [];
          this.loadingImages = false;
        });
    }
  }

  onSelectImage(ev) {
    this.src = ev;
    this.srcChange.emit(this.src);
    this.setSelectionMode(false);
  }

  onFileImageChange(fileInput) {
    const file = fileInput;
    this.uploading = true;
    const reader = new FileReader();
    reader.onload = () => this.toJpg(reader.result);
    reader.readAsDataURL(file);
  }

  setSelectionMode(status: boolean) {
    this.selectionMode = status;
    this.modeChange.emit(this.selectionMode ? 'edit' : 'show');
  }

  toJpg(data) {
    const image = new Image();
    image.onload = () => {
      // Max width
      if (image.width > this.MAX_IMAGE.WIDTH) {
        this.toastrService.info('Las imágenes deben tener un máximo de 1920px de ancho. Se escalará la imagen');
        const ratio = this.MAX_IMAGE.WIDTH / image.width;
        image.width = this.MAX_IMAGE.WIDTH;
        image.height = image.height * ratio;
      }
      // Max height
      if (image.height > this.MAX_IMAGE.HEIGHT) {
        this.toastrService.info('Las imágenes deben tener un máximo de 1080px de alto. Se escalará la imagen');
        const ratio = this.MAX_IMAGE.HEIGHT / image.height;
        image.height = this.MAX_IMAGE.HEIGHT;
        image.width = image.width * ratio;
      }

      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, image.width, image.height);
      this.doUploadImage(canvas.toDataURL('image/jpeg', 0.65));
    };
    image.src = data;
  }

  doUploadImage(imageData) {
    this.imagePickerService.uploadImage(imageData)
      .subscribe((response) => {
        this.images.push({ src: response.fileName });
        this.resetFileUpload();
      }, () => {
        this.resetFileUpload();
      });
  }

  resetFileUpload() {
    this.uploading = false;
    this.changed = true;
    setTimeout(() => {
      this.changed = false;
    }, 500);
  }

  onCroppedImage(d, ev) {
    /*  this.imageCropped = ev;
     this.imagesEdit.show = false;
     this.loadingImages = true;
     this.onUploadImage(ev); */

    d();
    this.imagesEdit.show = false;
    this.doUploadImage(ev);
  }

  onCancelImage(d) {
    this.imagesEdit.show = false;
    d();
  }

  onEdit(ev) {

    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    this.imagesEdit.show = true;
  }

  onShowCropper(modal, ev) {
    this.imagesEdit.show = true;
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    this.modalService.open(modal, { backdrop: 'static', size: 'lg', windowClass: 'image-cropper-modal'  });
  }

  onRemoveImageFromCloud(image) {

    let name = image.src.split('muventa/')[1];
    if (!name) {
      name = image.src.split('muventa-sandbox/')[1];
    }

    this.imagePickerService.deleteImage(name)
      .subscribe((r) => {
        const idx = this.images.indexOf(image);
        if (idx > -1) {
          this.images.splice(idx, 1);
        }
        this.toastrService.success('Se ha eliminado la imagen de la galeria')
      }, (e) => {
        console.log(e);
      })
  }
}
