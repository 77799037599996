import { Component, OnInit } from '@angular/core';
import { CKEditorDisableVersionCheck } from './shared/ckeditor/disable-version-check';

@Component({
    selector: 'mvta-root',
    template: '<router-outlet><spinner></spinner></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(
    private ckVersionChecker: CKEditorDisableVersionCheck,
  ) { }

  ngOnInit() {
    this.ckVersionChecker.initializeCheck();
  }
}
