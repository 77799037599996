import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * Este servicio maneja la manera en que se muestra la fecha en el input del NgbDatePicker
 */
@Injectable()
export class DMYFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ?
      date.day.toString().padStart(2, '0')
      + this.DELIMITER
      + date.month.toString().padStart(2, '0')
      + this.DELIMITER + date.year
      : '';
  }
}
