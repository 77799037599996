import { Address, DateAt } from '../shared/interfaces/common.interface';
import { OrderRevision, OrderRevisionItem } from './order-revision.interface';
import { OrderSplitTransaction, OrderSplitTransactionItem, SaleTransaction, SaleTransactionItem } from './sale-transaction.interface';

export const ORDER_STATUS = {
  // CANCELLED: 'cancelled',
  WITH_REFUNDS: 'with_refunds',
  PENDING_PAYMENT: 'payment',
  EXPIRED: 'expired',
  COMPLETED: 'completed',
  PENDING_APPROVAL: 'approval',
  CONV_STORE: 'convstore',
  PREAUTHORIZED: 'preauthorized',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
};

export const PACKAGE_STATUS = {
    PENDING: 'pending',
    PACKING: 'packing',
    ROUTED: 'routed',
    DELIVERED: 'delivered',
    READY_FOR_PICKUP: 'ready_for_pickup',
};
export const ORDER_TYPE = {
  STANDARD: 'standard',
  ALL: 'all',
  MARKETPLACE: 'marketplace',
  LOCAL_PICKUP: 'local_pickup',
};

export interface Destination {
  name?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  crossStreet?: string;
  reference?: string;
  city?: string;
  state?: string;
  country?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  postalCode?: string;
  integrations?: any;

  shippingMethod?: any;
}

export interface Dimensions {
  weight?: number;
  height?: number;
  width?: number;
  length?: number;
}

export interface Item {
  muventaSKU?: string;
  vendorSKU?: string;
  name?: string;
  attributes?: string;
  pType?: string;
  slug?: string;
  vendor?: string;
  vendorBusinessName?: string;
  dimensions?: Dimensions;
  quantity?: number;
  vendorPrice?: number;
  muventaPrice?: number;
  taxCollected?: number;
  profit?: number;
  total?: number;
  listPrice?: ItemListPrice | null;
  previousSKUOrderPrice?: ItemPreviousOrderPrice[] | null;
}

export interface ItemPreviousOrderPrice {
  sku: string;
  muventaPrice: number;
  vendorPrice: number;
  tax: number;
  oldMuventaPrice: number;
  oldVendorPrice: number;
  updatedAt: any;
}

export interface ItemListPrice {
  basePrice: number;
  calculatedPrice: boolean;
  price: number;
  tax: number;
}
export interface Package {
  version?: number;
  createdAt?: DateAt;
  updatedAt?: DateAt;
  createdBy?: string;
  modifiedBy?: string;
  id?: string;
  vendor?: string;
  orderId?: string;
  deliveryMode?: string;
  carrier?: string;
  trackingNum?: string;
  trackingUrl?: string;
  documentUrl?: string;
  collectedAt?: string;
  willDeliverAt?: string;
  deliveredAt?: string;
  status?: string;
  from?: Address;
  to?: Address;
  customerName?: string;
  customerId?: string;
  weight?: number;
  deliveryCost?: number;
  itemCost?: number;
  taxCollected?: number;
  profit?: number;
  items?: Item[];
  notifyUpdates?: boolean;
  deliveryDetails?: {
    willDeliverTo: string;
    willDeliverToPhone: string;
  };
}

export interface Payment {
  gateway?: string;
  url?: string;
  paid?: boolean;
  authorization?: string;
  operationId?: string;
  type?: string;
  card?: string;
  gatewayResponse?: string;
  payPalId?: string;
}

export interface Order {
  id?: string;
  publicId?: string;
  type?: string;
  version?: number;
  createdAt?: DateAt;
  updatedAt?: DateAt;
  paidAt?: DateAt;
  createdBy?: string;
  modifiedBy?: string;
  customerId?: string;
  customerEmail?: string;
  customerName?: string;
  salesmanId?: string;
  placedBy?: string;
  referredBy?: string;
  referredName?: string;
  destination?: Destination;
  billing?: any;
  comments?: string;
  packages?: Package[];
  allPackageItems?: Item[];
  reservations?: any[];
  payment?: Payment[];
  vendors?: string;
  coupons?: string;
  currency?: string;
  itemSubtotal?: number;
  subtotal?: number;
  taxesCollected?: number;
  deliveryCost?: number;
  deliveryDiscount?: number;
  deliveryTotal?: number;
  specialDiscount?: number;
  txFee?: number;
  eMoney?: number;
  eWallet?: number;
  profit?: number;
  total?: number;
  orderStatus?: string;
  rewardsProcessed?: boolean;
  itemsProcessed?: boolean;
  activatesMembership?: boolean;
  hasRefunds?: boolean;
  isGift?: boolean;
  giftNotes?: string;
  deliveryDate?: string;

  // Internal
  convStore?: boolean;
  warehouseLocation?: any;
  totalItems?: number;
}

export interface OrderDetails extends Order {
  saleTransactions?: SaleTransaction[];
  saleTransactionItems?: SaleTransactionItem[];
  refundTransactions?: SaleTransaction[];
  refundTransactionItems?: SaleTransactionItem[];
  orderRevision?: OrderRevision;
  orderRevisionItems?: OrderRevisionItem[];
  orderSplitTransactions?: OrderSplitTransaction[];
  orderSplitTransactionItems?: OrderSplitTransactionItem[];
}

export interface PageImplOrder {
  content: Array<Order>;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: Array<string>
  totalElements: number;
  totalPages: number;
}
