import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { AccountService } from '../shared/auth';
import { fadeInOutTranslate } from '../shared/elements/animation';
import { ThemesService } from './themes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mvta-themes',
  templateUrl: 'themes.component.html',
  styleUrls: ['./themes.component.scss'],
  providers: [ThemesService],
  animations: [fadeInOutTranslate]
})
export class ThemesComponent implements OnInit {

  loading = {
    available: true,
    installed: true,
  };
  availableThemes;
  installedThemes;
  siteURL = '';

  constructor(private router: Router, private themesService: ThemesService,
    private accountService: AccountService, private toastrService: ToastrService) {
    this.availableThemes = [];
    this.installedThemes = [];
  }

  ngOnInit() {
    const schema = (this.accountService.tokenDetails().aud || '').replace(/_/g, '-');
    this.siteURL = environment.siteBuilder.urlTemplate.replace('$schema', schema);
    this.getInstalledThemes();
  }

  getInstalledThemes() {
    this.loading.installed = true;
    this.themesService.getInstalledThemes()
      .subscribe((response) => {
        this.installedThemes = (response || []);
        this.getAvailableThemes();
        this.loading.installed = false;
      }, (error) => {
        console.error('Can not get installed themes', error);
        this.getAvailableThemes();
        this.loading.installed = false;
      });
  }

  getAvailableThemes() {
    this.loading.available = true;
    this.themesService.getAvailableThemes()
      .subscribe((response) => {
        this.availableThemes = (response || []).filter((t) => !this.installedThemes.some((i) => i.id === t.id));
        this.loading.available = false;
      }, (error) => {
        console.error('There was an error getting available themes', error);
        this.availableThemes = [];
        this.loading.available = false;
      });
  }

  onInstallTheme(theme, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    theme.loading = true;
    this.themesService.installTheme(theme.id)
      .subscribe(
        () => this.checkInstallation(theme),
        (error) => {
          console.error('There was an error installing the theme', error);
          this.toastrService.error('No se ha podido instalar el tema. Intenta de nuevo.');
          theme.loading = false;
        }
      );
  }

  onActivateTheme(theme, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    theme.loading = true;
    this.themesService.activateTheme(theme.id)
      .subscribe((response) => {
        if (response.activated) {
          theme.loading = false;
          theme.active = true;
          this.toastrService.success('Se ha activado el tema');
          this.router.navigate(['/tema', theme.id])
          return;
        }
        if (response.status === 'working') {
          this.toastrService.info('El tema aún se está instalando');
          theme.installing = true;
          theme.retries = 1;
          this.checkInstallation(theme);
        } else if (response.status === 'error') {
          swal({
            title: 'Error instalando el tema',
            text: 'Hubo un problema instalando el tema. ¿Deseas volver a intentarlo?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Si, reintentar'
          }).then((decision) => {
            if (decision.value) {
              this.onInstallTheme(theme, null);
            }
          });
        } else {
          this.toastrService.warning('No se ha podido activar el tema, intenta de nuevo.');
          theme.loading = false;
        }
      }, (error) => {
        console.error('Can not activate theme', error);
        this.toastrService.warning('No se ha podido activar el tema, intenta de nuevo.');
        theme.loading = false;
      });
  }

  checkInstallation(theme) {
    theme.loading = true;
    this.themesService.themeStatus(theme.id)
      .subscribe((response) => {
        if (response.status === 'installed') {
          theme.installed = true;
          theme.loading = false;
          if (!this.installedThemes.some((i) => i.active)) {
            this.onActivateTheme(theme, null);
          } else {
            this.getInstalledThemes()
            this.toastrService.success('Se ha instalado el tema');
          }
          return;
        }
        if (response.status === 'error') {
          theme.installed = false;
          theme.loading = false;
          swal({
            title: 'Error instalando el tema',
            text: 'Hubo un problema instalando el tema. ¿Deseas volver a intentarlo?',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Si, reintentar'
          }).then((decision) => {
            if (decision.value) {
              this.onInstallTheme(theme, null);
            }
          });
          return;
        }
      });
  }
}
