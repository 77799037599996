import { Injectable } from '@angular/core';
import { AccountService, AuthService } from './auth';

declare const tidioChatApi;

@Injectable()
export class TidioService {

  tidioChat;
  loaded = false;
  maxRetries = 10;
  retries = 0;

  constructor(private authService: AuthService, private accountService: AccountService) {
    this.initializeTidio();
  }

  initializeTidio() {
    if (typeof tidioChatApi === 'undefined') {
      this.retries++;
      if (this.retries < this.maxRetries) {
        setTimeout(() => this.initializeTidio(), 3000);
        return;
      }

      console.warn('Se alcanzó el máximo número de intentos esperando la carga de Tidio');
      return;
    }
    this.tidioChat = tidioChatApi;
    this.loaded = true;
    this.trackInfo();
  }

  trackInfo() {
    tidioChatApi.setVisitorData({
      email: this.authService.tokenDetails().email,
      name: this.authService.tokenDetails().name,
      tags: ['muventa', this.accountService.tokenDetails().name]
    });
  }

}
