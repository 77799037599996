import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberInParentheses'
})

export class NumberInsideParenthesesPipe implements PipeTransform {
  transform(value: string, defaultValue: any): any {
    const regExp = /^.*?\([^\d]*(\d+)[^\d]*\).*$/;
    const matches = regExp.exec(value);
    if (matches.length > 1) {
      return +matches[1];
    }

    return defaultValue;
  }
}
