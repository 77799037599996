import { Injectable } from '@angular/core';

declare global {
  interface Window {
    fbAsyncInit: any;
    b2chat_livechat_setup: any;
    dataLayer: any;
    recombeeIntegration: any;
    recombee: any;
    CKEDITOR: any;
  }
}

@Injectable()
export class CKEditorDisableVersionCheck {

  MAX_RETRIES_TO_INITIALIZE = 4;
  WAIT_TIME = 2 * 1000;
  retries = 0;

  constructor() {}

  public initializeCheck(): void {
    if (typeof window.CKEDITOR !== 'undefined' && window.CKEDITOR.config) {
      window.CKEDITOR.config.versionCheck = false;
    } else {
      this.retries += 1;
      if (this.retries > this.MAX_RETRIES_TO_INITIALIZE) {
        console.warn('No se ha cargado CKEditor');
        return;
      }
      setTimeout(() => this.initializeCheck(), this.WAIT_TIME);
    }
  }
}
