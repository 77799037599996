
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'mvta-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
    breadcrumbs: Array<Object>;
    hideBreadcrumb = false;

    constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {  // note, we don't use event
                this.breadcrumbs = [];
                let currentRoute = this.route.root;
                let url = '';
                this.hideBreadcrumb = false;
                do {
                    const childrenRoutes = currentRoute.children;
                    currentRoute = null;
                    childrenRoutes.forEach((childRoute) => {
                        if (childRoute.outlet === 'primary') {
                            const routeSnapshot = childRoute.snapshot;
                            url += '/' + routeSnapshot.url.map((segment) => segment.path).join('/');
                            if (childRoute.snapshot.data.breadcrumb !== undefined) {
                                let status = true;
                                if (childRoute.snapshot.data.status !== undefined) {
                                    status = childRoute.snapshot.data.status;
                                }

                                this.breadcrumbs.push({
                                    label: childRoute.snapshot.data.breadcrumb,
                                    status: status,
                                    url: url
                                });

                                this.titleService.setTitle(childRoute.snapshot.data.breadcrumb + ' | Muventa.com');
                            }
                            currentRoute = childRoute;
                        }
                        this.hideBreadcrumb = childRoute.snapshot.data.hideBreadcrumb === true;
                    })
                } while (currentRoute);

                if (!this.hideBreadcrumb) {
                    this.hideBreadcrumb = this.breadcrumbs.length === 0;
                }
            });
    }
}
