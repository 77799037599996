import { environment } from 'environments/environment';
import { versions } from 'environments/versions';

import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

import { AccountService } from '../auth/account.service';
import { AuthService } from '../auth/auth.service';

declare const Keen: any;

@Injectable()
export class Angulartics2KeenIO {
    private keenLoaded: boolean = false;
    private keen;

    constructor(private angulartics2: Angulartics2, private authService: AuthService, private accountService: AccountService) {
        this.angulartics2.pageTrack.subscribe((x: any) => this.pageTrack(x.path, x.location));
        this.angulartics2.eventTrack.subscribe((x: any) => this.eventTrack(x.action, x.properties));
        // this.angulartics2.setUsername.subscribe((x: string) => this.setUsername(x));
        // this.angulartics2.setUserProperties.subscribe((x: any) => this.setUserProperties(x));
        // this.angulartics2.setUserPropertiesOnce.subscribe((x: any) => this.setUserPropertiesOnce(x));
        // this.angulartics2.setSuperProperties.subscribe((x: any) => this.setSuperProperties(x));
        // this.angulartics2.setSuperPropertiesOnce.subscribe((x: any) => this.setSuperPropertiesOnce(x));
        // this.angulartics2.setAlias.subscribe((x: string) => this.setAlias(x));

        if (Keen) {
            this.keen = new Keen({
                projectId: environment.keenIO.projectId,
                writeKey: environment.keenIO.writeKey
            });
            this.keen.extendEvents(() => {
                return {
                    app: {
                        name: 'muventa-hq',
                        version: versions.version,
                        environment: environment.production ? 'production' : 'sandbox'
                    }
                };
            });
            this.keen.initAutoTracking();
            this.keenLoaded = true;
        } else {
            this.keenLoaded = false;
            console.warn('No se ha cargado el SDK de KeenIO');
        }
    }

    pageTrack(path: string, location: any) {
        if (!this.keenLoaded) {
            return console.warn('No se ha cargado el SDK de KeenIO');
        }
        if (environment.production) {
            this.keen.addEvent('Page Viewed', { page: path });
        }
    }

    eventTrack(action: string, properties: any) {
        if (!this.keenLoaded) {
            return console.warn('No se ha cargado el SDK de KeenIO');
        }
        if (properties !== null && typeof properties === 'object' &&
            !properties.hasOwnProperty('user') && this.authService.isLoggedIn()) {

            // Add user properties
            const user = this.authService.tokenDetails();
            user.account = {
                id: this.accountService.tokenDetails().aud,
                name: this.accountService.tokenDetails().name,
                tokenIssued: this.accountService.tokenDetails().iat
            };
            properties.user = user;
        }
        if (environment.production) {
            this.keen.addEvent(action, properties);
        }
    }
}
