export const CLEARSALE_STATUS = {
  APA: 'Automatic Approval',
  APM: 'Manual Approval',
  RPM: 'Denied without Prejudice',
  AMA: 'Manual Analysis',
  ERR: 'Error',
  NVO: 'New Order',
  SUS: 'Fraud Suspicion',
  CAN: 'Customer Asked for Cancellatio',
  FRD: 'Confirmed Fraud',
  RPA: 'Automatically Denied',
  RPP: 'Denied by Policy'
};
