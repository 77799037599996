import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Rewards } from 'app/loyalty/rewards/rewards.interface';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth';
import { Address } from './../shared/interfaces/common.interface';
import { StripeSettings } from './payment/stripe/stripe-settings.interface';
import {
  AccountPlan,
  AccountPlanFeature
} from './plan-payment/account-plan.interface';
import { AccountStripeOAuthLink, AccountStripeOAuthLinkInput, AccountStripeOAuthStatus } from './platform/stripe/account-stripe-oauth.interface';
import { TransactionFee } from './platform/transaction-fees/transaction-fee.interface';
import { StaffMember } from './staffMember.interface';

@Injectable()
export class AccountsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  // ---- Supplier services ----
  getInfo(account: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/admin/supplier/${account}`
    );
  }

  saveInfo(accountInfo): Observable<any> {
    delete accountInfo['createdAt'];
    delete accountInfo['updatedAt'];

    // Empty fields not allowed, fill empty with ND
    Object.keys(accountInfo).forEach((k) => {
      if (typeof accountInfo[k] === 'string' && accountInfo[k].length === 0) {
        accountInfo[k] = 'ND';
      }
    });

    return this.http.put(
      `${environment.apiUrl}/api/v1/admin/supplier`,
      accountInfo
    );
  }

  uploadLogo(imageData: string): Observable<any> {
    const formData = new FormData();
    formData.append('image', imageData);

    return this.http.post(
      `${environment.apiUrl}/api/v1/admin/supplier/logo`,
      formData
    );
  }

  deleteLogo(): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/v1/admin/supplier/logo`);
  }

  // ---- Account Services ----
  getSubscriptionDetails(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/account/subscription-details`
    );
  }

  getTerms(account: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/accounts/v1/${account}/terms`);
  }

  updateTerms(obj): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/v1/account/terms`, obj);
  }

  getMuventaPaymentGateways(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/account/muventa-payments`
    );
  }

  getPaymentGatewaysConfig(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/account/payment-gateways`
    );
  }

  updatePaymentGatewaysConfig(obj): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/api/v1/account/payment-gateways`,
      obj
    );
  }

  setMercadoPagoConfig(obj): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/api/v1/account/payment-gateways/mercado-pago`,
      obj
    );
  }

  setStripeSettings(settings: StripeSettings): Observable<StripeSettings> {
    return this.http.put<StripeSettings>(`${environment.apiUrl}/api/v1/account/payment-gateways/stripe`, {...settings});
  }

  removeStripeSettings(): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/v1/account/payment-gateways/stripe`);
  }

  getRewards(): Observable<Rewards> {
    return this.http.get<Rewards>(`${environment.apiUrl}/api/v1/account/rewards`);
  }

  updateRewards(obj): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/v1/account/rewards`, obj);
  }

  insertAddress(addr: Address): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/address`, addr);
  }

  updateAddress(addr: Address): Observable<any> {
    delete addr.createdAt;
    delete addr.updatedAt;

    return this.http.put(`${environment.apiUrl}/api/v1/admin/address`, addr);
  }

  getAddresses(): Observable<Array<Address>> {
    return this.http.get<Array<Address>>(
      `${environment.apiUrl}/api/v1/admin/addresses`
    );
  }

  getPaymentContact(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/account/payment-contact`
    );
  }

  updatePaymentContact(contact): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/account/payment-contact`,
      contact
    );
  }

  updateBilling(billing): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/account/billing`,
      billing
    );
  }

  getNotifEmails(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/account/notif-emails`);
  }

  setNotifEmails(notif): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/api/v1/account/notif-emails`,
      notif
    );
  }

  addEmployee(employee): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/employee`, employee);
  }

  getLogisticsSettings(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/logistics/settings`);
  }

  // ---- Plan Services ----
  getPlanAndPromotions(planId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/account/plan-promotions/${planId}`
    );
  }

  planPayment(payment): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/account/plan-payment`,
      payment
    );
  }

  planPaymentPaypalConfirm(payment): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/account/plan-payment/paypal-confirm`,
      payment
    );
  }

  getCurrentPlan(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/account/plan`);
  }

  // ---- Plan Services (v2) ----

  getAccountPlan(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/account-plan`);
  }

  getAvailablePlans(
    withFeatures: boolean = false,
    withPrices: boolean = false
  ): Observable<Array<AccountPlan>> {
    const qParams = new HttpParams({
      fromObject: {
        withFeatures: String(withFeatures),
        withPrices: String(withPrices),
      },
    });
    return this.http.get<Array<AccountPlan>>(
      `${environment.apiUrl}/api/v2/available-plans`,
      { params: qParams }
    );
  }

  getAvailableFeatures(
    withPrices: boolean = true
  ): Observable<Array<AccountPlanFeature>> {
    const qParams = new HttpParams({
      fromObject: {
        withPrices: String(withPrices),
      },
    });
    return this.http.get<Array<AccountPlanFeature>>(
      `${environment.apiUrl}/api/v2/available-features`,
      { params: qParams }
    );
  }

  getReceipts(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/billing/receipts`);
  }

  getReceipt(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/billing/receipt/${id}`);
  }

  receiptPayment(id: string, payment): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v2/billing/receipt/${id}/payment`,
      payment
    );
  }

  selectPlan(planSelectionInput, onlyRate: boolean = false): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        onlyRate: String(onlyRate),
      },
    });
    return this.http.post(
      `${environment.apiUrl}/api/v2/billing/select-plan`,
      planSelectionInput,
      { params: qParams }
    );
  }

  cancelPlanSubscription(): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v2/cancel-gateway-plan-subscription`,
      {}
    );
  }

  dueAndCycle(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/due-and-cycle`);
  }

  // ---- Employee Services ----
  getEmployees(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/employees`);
  }

  getAccessToken(account: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/accounts/v1/access/' + encodeURIComponent(account)
    );
  }

  deleteEmployee(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/v1/employee/${id}`);
  }
  getSuperAdmin(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/employees/admin`);
  }

  // ---- Staff Services
  getStaff(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/logistics/v1/staff`);
  }

  addStaffMember(staffMember: StaffMember): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/logistics/v1/staff`,
      staffMember
    );
  }

  deleteStaffMember(staffMemberId: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/logistics/v1/staff/${staffMemberId}`
    );
  }

  updateStaffMember(staffMember: StaffMember): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/logistics/v1/staff/${staffMember.id}`,
      staffMember
    );
  }

  // ---- Colaborators Services
  addColaborator(colaborator): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/colaborators`,
      colaborator
    );
  }

  deleteColaborator(colaboratorId: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/api/v1/colaborators/${colaboratorId}`
    );
  }

  updateColaborator(colaborator): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/colaborators/${colaborator.id}`,
      colaborator
    );
  }

  getColaborators(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/colaborators`);
  }

  // ---- Platform Stripe ----
  stripeOAuthStatus(refresh: boolean = false): Observable<AccountStripeOAuthStatus> {
    const qParams = new HttpParams({
      fromObject: {
        refresh: String(refresh),
      }
    });
    return this.http.get<AccountStripeOAuthStatus>(`${environment.apiUrl}/api/v1/account/oauth/stripe`, { params: qParams });
  }

  stripeOAuthLink(linkInput: AccountStripeOAuthLinkInput): Observable<AccountStripeOAuthLink> {
    return this.http.post<AccountStripeOAuthLink>(`${environment.apiUrl}/api/v1/account/oauth/stripe`, { ...linkInput });
  }

  transactionFees(fromDate: string = 'all'): Observable<TransactionFee[]> {
    const params = new HttpParams({
      fromObject: {
        fromDate
      }
    })
    return this.http.get<TransactionFee[]>(`${environment.apiUrl}/api/v1/account/fees`, { params });
  }
}
