import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Package, PageImplOrder } from 'app/orders/order.interface';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class OrdersService {
  constructor(private http: HttpClient) {}

  get(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/order/${id}`);
  }

  filter(
    status = '',
    type = '',
    from = '',
    shippingStatus = '',
    deliveryDate = '',
    term = '',
    to = '',
    deliveryDateTo = '',
  ): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        status: status.length > 0 ? status : 'all',
        type: type.length > 0 ? type : 'all',
        fromDate: from.length > 0 ? from : 'all',
        toDate: to.length > 0 ? to : '',
        shippingStatus: shippingStatus.length > 0 ? shippingStatus : 'all',
        deliveryDate,
        deliveryDateTo,
        page: '0',
        rows_per_page: '0',
        term,
      }
    });

    return this.http.get(`${environment.apiUrl}/api/v1/admin/orders`, {
      params: qParams,
    });
  }

  filterWithPagination(
    status = '',
    type = '',
    creationDateFrom = '',
    shippingStatus = '',
    deliveryDate = '',
    term = '',
    creationDateTo = '',
    deliveryDateTo = '',
    paidAtFrom = '',
    paidAtTo = '',
    page = '0',
    sort = [],
    size = '0',
  ): Observable<PageImplOrder> {
    const qParams = new HttpParams({
      fromObject: {
        status: status.length > 0 ? status : 'all',
        type: type.length > 0 ? type : 'all',
        fromDate: creationDateFrom.length > 0 ? creationDateFrom : 'all',
        toDate: creationDateTo.length > 0 ? creationDateTo : '',
        shippingStatus: shippingStatus.length > 0 ? shippingStatus : 'all',
        deliveryDate,
        deliveryDateTo,
        paidAtFrom,
        paidAtTo,
        page,
        sort,
        size,
        term,
      }
    });

    return this.http.get<PageImplOrder>(`${environment.apiUrl}/api/v2/admin/orders`, {
      params: qParams,
    });
  }

  updatePackage(
    pkg: Package,
    notify: boolean = true,
    isLocal: boolean = false
  ): Observable<any> {
    const data = Object.assign({}, pkg);
    delete data['updatedAt'];
    delete data['createdAt'];
    if (
      data.to.shippingMethod !== undefined &&
      data.to.shippingMethod.warehouseLocation !== undefined
    ) {
      delete data.to.shippingMethod.warehouseLocation.address['updatedAt'];
      delete data.to.shippingMethod.warehouseLocation.address['createdAt'];
    }
    if (
      data.from.shippingMethod !== undefined &&
      data.from.shippingMethod.warehouseLocation !== undefined
    ) {
      delete data.from.shippingMethod.warehouseLocation.address['updatedAt'];
      delete data.from.shippingMethod.warehouseLocation.address['createdAt'];
    }
    data.items = data.items.map((i) => {
      if (i.previousSKUOrderPrice && Array.isArray(i.previousSKUOrderPrice) && i.previousSKUOrderPrice.length > 0) {
        i.previousSKUOrderPrice = i.previousSKUOrderPrice.map((p) => {
          const psku = {...p};
          delete psku.updatedAt;

          return psku;
        });
      }
      return i;
    });

    const qParams = new HttpParams({
      fromObject: {
        notify: String(notify),
        local: String(isLocal),
      },
    });

    return this.http.put(`${environment.apiUrl}/api/v1/admin/package`, data, {
      params: qParams,
    });
  }

  updateStatus(id: string, status: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/order', {
      orderId: id,
      status: status,
    });
  }

  markAsPaid(id: string, reason: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/order/paid', {
      orderId: id,
      reason: reason,
    });
  }

  searchOrders(term, page, rowsPerPAge): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        status: 'all',
        term: term,
      },
    });
    return this.http.get(environment.apiUrl + `/api/v1/admin/orders/search`, {
      params: qParams,
    });
  }

  getProductsDetails(skus): Observable<any> {
    return this.http.post(
      environment.apiUrl + `/api/v1/admin/store/products-info`,
      skus
    );
  }

  getDashboardInfo(): Observable<any> {
    return this.http.get(environment.apiUrl + `/api/v1/admin/dashboard`);
  }

  generateAuthCode(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/generate-auth-code`,
      data
    );
  }

  refundItems(orderId: string, refundInput): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/admin/order/${orderId}/refund`,
      refundInput
    );
  }

  refundTransactions(orderId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/admin/order/${orderId}/refunds`
    );
  }

  getOrderStatusListReport(
    fromDate: string
  ): Observable<OrderStatusListRecord[]> {
    const qParams = new HttpParams({
      fromObject: {
        fromDate: fromDate.length > 0 ? fromDate : 'all',
      },
    });
    return this.http.get<OrderStatusListRecord[]>(
      `${environment.apiUrl}/api/report/v1/orders-status-list`,
      { params: qParams }
    );
  }

  /* 3PL */
  getShipmentsFromOrder(orderId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/3pl/v1/shipments/from-order/${orderId}`
    );
  }

  getLabelsFromOrder(orderId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/3pl/v1/guides/from-order/${orderId}`
    );
  }

  getCoverage(
    postalCodeOrigin: string,
    postalCodeDestination: string
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/3pl/v1/coverage/${postalCodeOrigin}/${postalCodeDestination}`
    );
  }

  getQuotes(shipmentIds): Observable<any> {
    return this.http.post(`${environment.apiUrl}/3pl/v1/quotes`, shipmentIds);
  }

  createShipments(shipments): Observable<any> {
    return this.http.post(`${environment.apiUrl}/3pl/v1/shipments`, shipments);
  }

  createGuides(shipmentIds): Observable<any> {
    return this.http.post(`${environment.apiUrl}/3pl/v1/guides`, shipmentIds);
  }

  createBox(box): Observable<any> {
    return this.http.post(`${environment.apiUrl}/3pl/v1/boxes`, box);
  }

  getBoxes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/3pl/v1/boxes`);
  }

  updateBox(boxId: string, box): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/3pl/v1/boxes/${boxId}`, box);
  }

  createAddress(address): Observable<any> {
    return this.http.post(`${environment.apiUrl}/3pl/v1/warehouses`, address);
  }

  getAddresses(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/3pl/v1/warehouses`);
  }

  updateAddress(addressId: string, address): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/3pl/v1/warehouses/${addressId}`,
      address
    );
  }

  updateTracking(guideIds): Observable<any> {
    return this.http.put(`${environment.apiUrl}/3pl/v1/tracking`, guideIds);
  }

  getGuideListing(fromDate, toDate, status = 'all'): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        fromDate,
        toDate,
        status,
      },
    });
    return this.http.post(
      `${environment.apiUrl}/3pl/v1/guides-list`,
      {},
      {
        params: qParams,
      }
    );
  }
}
