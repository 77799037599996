import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ThemesService {

    constructor(private http: HttpClient) { }

    getAvailableThemes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/v1/admin/themes/available`);
    }

    getInstalledThemes(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/v1/admin/themes/installed`);
    }

    installTheme(theme: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/v1/admin/theme/${theme}/install`, {});
    }

    themeStatus(theme: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/api/v1/admin/theme/${theme}/status`);
    }

    activateTheme(theme: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/api/v1/admin/theme/${theme}/activate`, {});
    }
}
