import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyResizingCroppingImageModule } from '@alyle/ui/resizing-cropping-images';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { ChartModule } from 'angular2-chartjs';
import { TextMaskModule } from 'angular2-text-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { CKEditorModule } from 'ng2-ckeditor';
import { FileUploadModule } from 'ng2-file-upload';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { UiSwitchModule } from 'ng2-ui-switch/dist';
import { TagInputModule } from 'ngx-chips';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OrderDetailDetailsComponent } from '../orders/detail/order-details/order-details.component';
import { OrderPaymentDetailsComponent } from '../orders/detail/payment-details/payment-details.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './accordion';
import { AddressesComponent } from './addresses/addresses.component';
import { ArrowBoxComponent } from './arrow-box/arrow-box.component';
import { AccountService, AuthService } from './auth';
import { CardRefreshDirective } from './card/card-refresh.directive';
import { CardToggleDirective } from './card/card-toggle.directive';
import { CardComponent } from './card/card.component';
import { ColorPickerDebounceComponent } from './color-picker/color-picker.component';
import { CreditCardManagerComponent } from './credit-card-manager/credit-card-manager.component';
import { CustomCheckComponent } from './custom-check/custom-check.component';
import { InputEditComponent } from './custom-input-edit-text/custom-input-edit-text.component';
import { DateRangeHiddenComponent } from './date-range-hidden/date-range-hidden.component';
import { DateRangePickerComponent } from './date-range/date-range.component';
import { DateRangePipe } from './date-range/date-range.pipe';
import { DuePaymentModalComponent } from './due-payment/due-payment.component';
import { DuePaymentModalDirective } from './due-payment/due-payment.directive';
import { DatatableEditCellComponent } from './edit-cell/edit-cell.component';
import { BytesPipe } from './elements/bytes.pipe';
import { DataFilterPipe } from './elements/data-filter.pipe';
import { FocusDirective } from './elements/focus.directive';
import { GravatarDirective } from './elements/gravatar.directive';
import { ImgFallbackDirective } from './elements/img-fallback.directive';
import { KeysPipe } from './elements/keys.pipe';
import { SafeHtmlPipe } from './elements/safe-html.pipe';
import { FilePickerComponent } from './file-picker/file-picker.component';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { InterceptorModule } from './http/interceptor';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { ImageCropperComponent } from './img-cropper/img-cropper.component';
import { LinkEditorComponent } from './link-editor/link-editor.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MenuItems } from './menu-items/menu-items';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { TidioService } from './tidio.service';
import { ArrayIndexesPipe } from './utils/array-indexes.pipe';
import { FindInArrayPipe } from './utils/find-in-array.pipe';
import { NumberInsideParenthesesPipe } from './utils/number-in-parentheses.pipe';
import { SplitArrayPipe } from './utils/split-array.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule.forRoot(),
    CKEditorModule,
    TagInputModule,
    UiSwitchModule,
    FileUploadModule,
    ChartModule,
    ClickOutsideModule,
    InterceptorModule,
    ColorPickerModule,
    ClipboardModule,
    TextMaskModule,
    ShareButtonsModule.forRoot(),
    NgxDatatableModule,
    NgxDnDModule,
    LyButtonModule,
    LyIconModule,
    LyResizingCroppingImageModule,
    LyThemeModule.setTheme('minima-light'),
    LyToolbarModule,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    CardComponent,
    ImgFallbackDirective,
    SpinnerComponent,
    ModalBasicComponent,
    DataFilterPipe,
    DateRangePipe,
    SplitArrayPipe,
    FindInArrayPipe,
    KeysPipe,
    ArrayIndexesPipe,
    BytesPipe,
    LoadingIndicatorComponent,
    GravatarDirective,
    FocusDirective,
    AddressesComponent,
    CreditCardManagerComponent,
    ImagePickerComponent,
    DuePaymentModalComponent,
    DuePaymentModalDirective,
    ImageCropperComponent,
    DatatableEditCellComponent,
    OrderPaymentDetailsComponent,
    OrderDetailDetailsComponent,
    DateRangePickerComponent,
    DateRangeHiddenComponent,
    InputEditComponent,
    CustomCheckComponent,
    ArrowBoxComponent,
    IconPickerComponent,
    SafeHtmlPipe,
    NumberInsideParenthesesPipe,
    FilePickerComponent,
    LinkEditorComponent,
    ColorPickerDebounceComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullscreenDirective,
    CardRefreshDirective,
    CardToggleDirective,
    CardComponent,
    SpinnerComponent,
    NgbModule,
    CKEditorModule,
    FormsModule,
    ImgFallbackDirective,
    ReactiveFormsModule,
    ModalBasicComponent,
    TagInputModule,
    UiSwitchModule,
    DataFilterPipe,
    DateRangePipe,
    SplitArrayPipe,
    FindInArrayPipe,
    KeysPipe,
    ArrayIndexesPipe,
    FileUploadModule,
    Ng2GoogleChartsModule,
    ChartModule,
    ClickOutsideModule,
    ColorPickerModule,
    ClipboardModule,
    TextMaskModule,
    LoadingIndicatorComponent,
    GravatarDirective,
    FocusDirective,
    AddressesComponent,
    ShareButtonsModule,
    NgxDatatableModule,
    ImageCropperModule,
    NgxDnDModule,
    CreditCardManagerComponent,
    ImagePickerComponent,
    DuePaymentModalComponent,
    DuePaymentModalDirective,
    ImageCropperComponent,
    DatatableEditCellComponent,
    OrderPaymentDetailsComponent,
    OrderDetailDetailsComponent,
    DateRangePickerComponent,
    DateRangeHiddenComponent,
    InputEditComponent,
    CustomCheckComponent,
    ArrowBoxComponent,
    IconPickerComponent,
    SafeHtmlPipe,
    NumberInsideParenthesesPipe,
    FilePickerComponent,
    LinkEditorComponent,
    ColorPickerDebounceComponent,
  ],
  providers: [
    MenuItems,
    AuthService,
    AccountService,
    CurrencyPipe,
    TidioService,
    { provide: LY_THEME, useClass: MinimaLight, multi: true }
  ]
})
export class SharedModule { }
