import { CurrencyPipe } from '@angular/common';
import { Injectable, TemplateRef } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DuePaymentState {
  private dueAndCycle: any = null;
  private message = '';

  modal: NgbModalRef;
  template: TemplateRef<any>;

  dueChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private currencyPipe: CurrencyPipe) { }

  setDueAndCycle(dueAndCycle) {
    this.dueAndCycle = dueAndCycle;
    if (!dueAndCycle || Object.keys(dueAndCycle).length === 0) {
      this.message = '';
      return;
    }
    this.dueChange.next(this.dueAndCycle);

    this.message = `Estimado cliente, tu cuenta presenta un adeudo de ` +
      `<strong>${this.currencyPipe.transform(dueAndCycle.dueBeforeTax, 'USD', 'symbol', '1.0-2')}</strong> + IVA.`;
    if (this.dueAndCycle.cycleStartAt !== null) {
      const now = moment().utc().startOf('day');

      if (this.dueAndCycle.overdueCount === 1) {
        const disableFeatures = moment(this.dueAndCycle.cycleStartAt.epochSecond * 1000).add(5, 'days');
        const disableCart = moment(this.dueAndCycle.cycleStartAt.epochSecond * 1000).add(1, 'month');
        if (now > disableFeatures) {
          this.message = `${this.message} Se desactivará el carrito a partir de
            <strong>${disableCart.format('DD/MM/YYYY')}</strong>`;
        } else {
          this.message = `${this.message} Se desactivarán características a partir de
            <strong>${disableFeatures.format('DD/MM/YYYY')}</strong>`;
        }
      } else if (this.dueAndCycle.overdueCount === 2) {
        const disableCart = moment(this.dueAndCycle.cycleStartAt.epochSecond * 1000);
        const disableWebsite = moment(this.dueAndCycle.cycleStartAt.epochSecond * 1000).add(5, 'days');
        if (now > disableCart) {
          this.message = `${this.message} Se desactivará el carrito a partir de
            <strong>${disableCart.format('DD/MM/YYYY')}</strong>`;
        } else {
          this.message = `${this.message} Se desactivará el sitio web a partir de
            <strong>${disableWebsite.format('DD/MM/YYYY')}</strong>`;
        }
      }
    }
  }

  getDueAndCycle() {
    return this.dueAndCycle;
  }

  getMessage() {
    return this.message;
  }
}
