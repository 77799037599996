import { Location } from '@angular/common';
import { AfterContentChecked, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { AccountsService } from '../../account/account.service';
import { AccountService } from '../../shared/auth/account.service';
import { Address } from '../../shared/interfaces/common.interface';
import { Order, ORDER_STATUS, ORDER_TYPE, PACKAGE_STATUS, Payment } from '../order.interface';
import { OrdersService } from '../orders.service';

@Component({
  selector: 'mvta-order-print',
  templateUrl: 'print.component.html',
  styleUrls: ['./print.component.scss'],
  providers: [AccountsService, OrdersService],
  encapsulation: ViewEncapsulation.None
})
export class OrderPrintComponent implements OnInit, AfterContentChecked {

  PACKAGE_STATUS = PACKAGE_STATUS;
  ORDER_STATUS = ORDER_STATUS;
  ORDER_TYPE = ORDER_TYPE;
  packageStatusLabels = {};
  orderId;
  order: Order;
  orderPayments: Payment[];
  loadingOrder = true;

  businessName = '';
  accountInfo;
  addressFrom = '';

  cardPayment = false;
  hour;
  errorDetails;
  cardHolder;
  offline = {
    status: false,
    passed: false,
    gateway: '',
    ticket: '',
    total: ''
  }
  gatewayFilter;
  amountFilter;
  skus = [];

  refundedTx = [];

  autoPrint = false;
  dataRetrieved = false;
  printFired = false;
  DEFAULT_TAX = 16;
  taxesCollected = 0;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location,
    private ordersService: OrdersService, private accountService: AccountService,
    private accountsService: AccountsService, private modalService: NgbModal) {
    this.packageStatusLabels[PACKAGE_STATUS.PENDING] = 'Pendiente';
    this.packageStatusLabels[PACKAGE_STATUS.ROUTED] = 'En tránsito';
    this.packageStatusLabels[PACKAGE_STATUS.DELIVERED] = 'Entregada'
  }

  ngOnInit() {
    this.businessName = this.accountService.tokenDetails().name.trim();

    this.route.params.subscribe((params) => {
      this.orderId = params['id'];
      let path = this.route.snapshot.url.length ? this.route.snapshot.url[0].path : '';
      path = path.trim().toLowerCase();

      if (!this.orderId || !this.orderId.length) {
        this.router.navigate(['../'], { relativeTo: this.route });
        return;
      }

      this.getAccountInfo();
      this.getOrder();
    });
    this.route.queryParams.subscribe((qParams) => {
      this.autoPrint = !!qParams.print;
    });
  }

  ngAfterContentChecked() {
    if (this.dataRetrieved && this.autoPrint) {
      this.autoPrint = false;
      this.onPrint();
    }
  }

  onPrint() {
    setTimeout(() => {
      window.print();
    }, 1);
  }

  getOrder() {
    this.loadingOrder = true;
    this.ordersService.get(this.orderId)
      .subscribe((orderResponse) => {
        this.getSkus(orderResponse);
        if (orderResponse.hasRefunds) {
          this.getRefundedTransactions();
        }
      }, (error) => {
        console.error('Order not found', error);
        this.loadingOrder = false;
        swal({
          type: 'error',
          title: 'Orden no Encontrada',
          text: 'No se encontró la orden'
        }).then(() => {
          this.router.navigate(['../'], { relativeTo: this.route });
        });
      });
  }

  getRefundedTransactions() {
    this.ordersService.refundTransactions(this.orderId)
      .subscribe((response) => {
        this.refundedTx = response || [];
      }, (error) => {
        console.error('Can not get refunded transactions', error);
        this.refundedTx = [];
      });
  }

  getHour() {
    const d = new Date(0);
    d.setUTCSeconds(this.order.createdAt.epochSecond);
    this.hour = `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  }

  getDetail() {
    // IF RECHAZADA
    if (this.order.orderStatus === 'payment') {
      let flag = false;

      if (this.order.payment.length <= 0) {
        return;
      }
      flag = this.order.payment.some((p) => (p.type === 'creditcard' && !p.paid));
      if (flag) {
        const obj = this.order.payment.find((p) => (p.type === 'creditcard' && !p.paid));
        let myData = [];
        myData = JSON.parse(obj.gatewayResponse);
        this.errorDetails = (myData['error_message']);
        if (myData['error_message'] === null) {
          this.errorDetails = (myData['status']);
        }
      }
    }
  }

  getAccountInfo() {
    this.accountsService.getInfo(this.accountService.tokenDetails().aud)
      .subscribe((response) => {
        this.accountInfo = response;
        this.accountInfo.cleanPhone = this.accountInfo.phone.replace(/[^0-9\+]/g, '').trim();
        this.addressFrom = [
          this.accountInfo.address,
          this.accountInfo.city,
          this.accountInfo.state.toUpperCase()
        ].filter((i) => i !== undefined && String(i).trim().length > 0).join();
      });
  }

  onBillingDetails(modal, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    this.modalService.open(modal);
  }

  printAddress(addr: Address): string {
    const fields = [
      addr.addressLine1,
      addr.addressLine2.length ? 'Int ' + addr.addressLine2 : '',
      addr.addressLine3,
      addr.city,
      addr.postalCode,
      addr.state.toUpperCase(),
      addr.country.toUpperCase()
    ].filter((f) => f.trim().length !== 0 && f.trim().toLowerCase() !== 'na');

    return fields.join(', ');
  }

  printPaymentId(payment: Payment): string {
    let paymentId = payment.operationId || '';
    if (payment.gateway === 'paypal') {
      try {
        const gwResponse = JSON.parse(payment.gatewayResponse);
        paymentId = gwResponse.id || '';
      } catch (e) {
        paymentId = '';
      }
    }
    if (paymentId.length > 4) {
      const part1 = paymentId.split('|')[0];
      paymentId = part1.substring(part1.length - 4);
    }

    return paymentId;
  }

  printAttributes(attrs: string): string {
    const sAttrs = attrs.split('-').map((a) => a.trim()).filter((fa) => fa.length && fa !== 'na').map((a) => a.replace('\|', ': '));
    return sAttrs.length ? sAttrs.join(' - ') : '';
  }

  // checkOffline(order: Order) {
  //   const proccessPayment = order.payment.find((p) => p.authorization === 'in_progress');
  //   this.offline.status = (proccessPayment !== undefined);
  //   if (this.offline.status) {
  //     const id = proccessPayment.operationId;
  //     this.offline.passed = order.payment.some((p) => p.operationId === id && p.authorization === 'charge.succeeded');
  //     if (this.offline.passed) {
  //       this.offline.ticket = proccessPayment.operationId.substring(37, 41);
  //       const theGatewayResponse = this.parseJson(proccessPayment.gatewayResponse);
  //       this.offline.total = theGatewayResponse['amount'];
  //     }
  //   }
  // }

  paymentFilter(order: Order) {
    if (order.orderStatus === 'completed') {
      if (order.payment.some((p) => p.type === 'creditcard' && p.paid)) {
        this.cardPayment = true;
        this.gatewayFilter = 'Tarjeta de Credito';
      } else if (order.payment.some((p) => p.gateway === 'mercadopago' && p.paid)) {
        this.gatewayFilter = 'Mercado Pago';
      } else if (order.payment.some((p) => p.gateway === 'stripe' && p.paid)) {
        this.gatewayFilter = 'Stripe';
      }  else if (order.payment.some((p) => p.type === 'paypal')) {
        this.gatewayFilter = 'Paypal';
      } else if (order.payment.some((p) => p.type === 'convstore')) {
        this.gatewayFilter = 'Tienda de Conveniencia';
      } else if (order.payment.some((p) => p.type === 'spei')) {
        this.gatewayFilter = 'Transferencia Bancaria';
      }

      this.amountFilter = this.getTotalFromGwTransactions(order);
    }
  }

  getTotalFromGwTransactions(order: Order): number {
    const paidTx = order.payment.filter((p) => p.paid) || [];
    const uniqueTx = paidTx
      .filter((tx, pos, arr) => arr
        .findIndex((a) => a.operationId === tx.operationId && a.authorization === tx.authorization) === pos);

    const amount = uniqueTx.reduce((prev, cur) => {
      const tx = this.parseJson(cur.gatewayResponse);
      let txAmount = 0;
      if (cur.gateway === 'openpay') {
        const opTx = tx['transaction'] || false;
        txAmount = (opTx ? opTx.amount : tx['amount']) || 0;
      } else if (cur.gateway === 'paypal') {
        txAmount = tx['transactions'].reduce((ppPrev, ppActual) => {
          const ppAmount = Number(String(ppActual.amount.total || '0'));
          return ppPrev + ppAmount;
        }, 0);
      } else if (cur.gateway === 'mercadopago') {
        const optTx = tx['transactionAmount'] || '0';
        txAmount = Number(optTx) || 0;
      } else if (cur.gateway === 'stripe') {
        const optTx = tx['amount'] || '0';
        const centsAmount = Number(optTx);
        txAmount = centsAmount > 0 ? centsAmount / 100 : 0;
      }

      return prev + txAmount;
    }, 0);

    return amount;
  }

  getCardHolderName(order: Order) {
    if (this.cardPayment) {
      const pyResponse = order.payment.find((p) => p.gateway === 'openpay' && p.paid === true);
      const bankData = this.parseJson(pyResponse.gatewayResponse);
      this.cardHolder = bankData.card ? bankData.card.holder_name : '';
      if (this.cardHolder.length === 0 && bankData.transaction !== undefined) {
        this.cardHolder = bankData.transaction.card ? bankData.transaction.card.holder_name || '' : '';
      }
    }
  }

  parseJson(json: string): any {
    let parsedJson = {};
    try {
      parsedJson = JSON.parse(json);
    } catch (e) {
      parsedJson = {};
    }

    return parsedJson;
  }

  getSkus(order) {
    order.packages[0].items.forEach((item) => {
      this.skus.push(item.muventaSKU);
    });

    this.ordersService.getProductsDetails(this.skus)
      .subscribe((response) => {
        if (response && Object.keys(response).length > 0) {
          order.packages[0].items.forEach((item) => {
            if (!response.hasOwnProperty(item.muventaSKU)) {
              return;
            }
            item.tags = response[item.muventaSKU].tags || '';
            const customTags = {};
            item.tags.split(' ').forEach((t) => {
              const keyValue = t.split(':');
              if (keyValue.length < 2) {
                return;
              }
              customTags[keyValue[0]] = [...keyValue.slice(1, keyValue.length)]
                .join('').split('_').join(' ');
            });
            item.customTags = customTags;
          });
        }
        this.order = order;
        if (this.order.type === 'local_pickup' && this.order.destination.shippingMethod.type === 'pickup') {
          this.order.warehouseLocation = this.order.destination.shippingMethod.warehouseLocation;
        }
        this.orderPayments = this.order.payment.filter((p) => p.paid);
        if (this.orderPayments.length > 1) {
          this.orderPayments = [this.orderPayments[0]];
        }
        this.loadingOrder = false;
        this.getHour();
        this.getDetail();
        // this.checkOffline(this.order);
        this.paymentFilter(this.order);
        this.getCardHolderName(this.order);
        this.calculateCollectedTaxes();
        this.dataRetrieved = true;
      }, (error) => {
        console.error(error)
      })
  }

  private calculateCollectedTaxes(): void {
    let collectedTaxes = 0;
    for (const item of this.order.allPackageItems) {
      const taxPercentage = item.listPrice ? item.listPrice.tax : this.DEFAULT_TAX;
      if (taxPercentage > 0) {
        // Total sin impuestos: total / 1 + (percentage / 100)
        // p.e. 116 con 16% = 116 / 1 + (16 / 100) = 116 / 1.16
        const totalWithoutTax = item.total / (1 + (taxPercentage / 100));
        collectedTaxes += Math.round((item.total - totalWithoutTax) * 100) / 100;
      }
    }

    this.taxesCollected = Math.round(collectedTaxes * 100) / 100;
  }
}
