import { Component, OnInit } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { AuthService } from '../shared/auth';

@Component({
    selector: 'mvta-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    firstName = '';
    storageKey = 'mvta-quickstart-settings';
    settings;
    availableTips = ['addProduct', 'setStock', 'configLogistics', 'configPayments', 'terms', 'configCart', 'links'];
    hasTips = true;
    canResetSettings = false;

    constructor(private authService: AuthService, private angulartics2: Angulartics2) {
        this.settings = {};
    }

    ngOnInit() {
        this.firstName = this.authService.tokenDetails().name.trim().split(' ')[0];
        this.getSettings();
    }

    isDone(key) {
        return this.settings.hasOwnProperty(key) && this.settings[key];
    }

    onDone(key) {
        this.angulartics2.eventTrack.next({
            action: 'home',
            properties: {
                developerMessage: 'User mark as a done a tip',
                tip: key
            }
        });
        this.settings[key] = true;
        this.saveSettings();
    }

    onResetTips(ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.angulartics2.eventTrack.next({
            action: 'home',
            properties: {
                developerMessage: 'User reset the tips',
            }
        });
        this.settings = {};
        this.saveSettings();
    }

    getSettings() {
        this.settings = {};
        try {
            this.settings = JSON.parse(localStorage.getItem(this.storageKey)) || {};
        } catch (e) {
            this.settings = {};
        }
        this.canResetSettings = Object.keys(this.settings).some((k) => this.settings[k]);
        this.hasTips = this.availableTips.some((a) => !this.settings[a]);
    }

    saveSettings() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.settings));
        this.canResetSettings = Object.keys(this.settings).some((k) => this.settings[k]);
        this.hasTips = this.availableTips.some((a) => !this.settings[a]);
    }
}
