import { Component, Input, OnChanges } from '@angular/core';
import { CLEARSALE_STATUS } from '../../../orders/clearsale-status-list';
import { OrderDetails, ORDER_STATUS } from '../../../orders/order.interface';

@Component({
  selector: 'mvta-order-payment-details',
  templateUrl: './payment-details.component.html',
  styles: [`.payment-id {
    max-width: 150px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    display: block;
  }`]
})
export class OrderPaymentDetailsComponent implements OnChanges {

  ORDER_STATUS = ORDER_STATUS;
  CLEARSALE_STATUS = CLEARSALE_STATUS;

  @Input() order: OrderDetails;

  constructor() { }

  ngOnChanges() { }

}
