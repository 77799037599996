import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TagsAndCategories } from 'app/website/website.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth';
import { Bundle } from './bundles/bundle.interface';
import { ProductCustomField } from './custom-fields/custom-field.interface';
import { ProductPriceValidityInOrderPolicy } from './order-prices-policy/product-price-validity-in-order-policy.interface';
import { PriceList } from './price-list.interface';
import { PriceListV2, PriceListV2Price, PriceListV2PriceInput } from './prices-list/price-list-v2';
import { ProductPriceList } from './product-price-list.interface';
import { Variant } from './variant.interface';

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  // ----- Products endpoints ----
  findBySlug(slug: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/api/v1/admin/product/slug/' + slug
    );
  }

  findById(id: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/admin/product/${id}`);
  }

  create(data: Object): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/admin/product', data);
  }

  createProduct(data: Object): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v2/products', data);
  }

  update(data: Object): Observable<any> {
    delete data['createdAt'];
    delete data['updatedAt'];

    return this.http.put(environment.apiUrl + '/api/v1/admin/product', data);
  }

  patchProduct(productId: string, props): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/product/${productId}`,
      props
    );
  }

  patchSku(sku: string, props): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/variant/${sku}`,
      props
    );
  }

  uploadImage(productId: string, imageData: string): Observable<any> {
    const formData = new FormData();
    formData.append('productId', productId);
    formData.append('image', imageData);

    return this.http.post(
      environment.apiUrl + '/api/v1/admin/product/image',
      formData
    );
  }

  images(productId: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/api/v1/admin/product/' + productId + '/images'
    );
  }

  deleteImage(productId: string, name: string): Observable<any> {
    return this.http.delete(
      environment.apiUrl +
        '/api/v1/admin/product/' +
        productId +
        '/image?name=' +
        encodeURI(name)
    );
  }

  getStoreProducts(
    q: string = '',
    onlyArchived: boolean = false,
    page: number = 0,
    size: number = 100
  ): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        q,
        onlyArchived: String(onlyArchived),
        page: String(page),
        size: String(size),
      },
    });
    return this.http.get(`${environment.apiUrl}/api/v1/admin/store/products`, {
      params: qParams,
    });
  }

  updateStoreProduct(storeProduct): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/admin/store/product`,
      storeProduct
    );
  }

  archiveVariant(sku): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/variant/${sku}/archive`,
      {}
    );
  }

  updateStoreVariant(storeProduct): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/admin/store/variant`,
      storeProduct
    );
  }

  updateProductsInstalledCapacity(
    skus: Array<String>,
    isInstalled: boolean
  ): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        status: String(isInstalled),
      },
    });
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/variants/installed-capacity`,
      skus,
      { params: qParams }
    );
  }

  updateStoreVariantStock(storeProduct): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/admin/store/stock`,
      storeProduct
    );
  }

  // ---- Bundle Endpoints ----
  createNewBundle(bundle: Bundle): Observable<Bundle> {
    return this.http.post(`${environment.apiUrl}/api/v1/admin/bundle`, bundle);
  }
  updateBundleDetails(bundleId: string, data): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/api/v1/admin/bundle/${bundleId}/details`,
      data
    );
  }
  updateBundle(bundle: Bundle): Observable<Bundle> {
    return this.http.put(
      `${environment.apiUrl}/api/v1/admin/bundle/${bundle.productId}`,
      bundle
    );
  }
  getAllBundles(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/store/bundles`);
  }
  getBundleBySlug(
    slug: string,
    includeItems: boolean = false
  ): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        includeItems: String(includeItems),
      },
    });
    return this.http.get(`${environment.apiUrl}/api/v1/store/bundle/${slug}`, {
      params: qParams,
    });
  }
  getSkus(list: String[]): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        onlyEnabled: 'false',
      },
    });
    return this.http.post(`${environment.apiUrl}/api/v1/store/sku-list`, list, {
      params: qParams,
    });
  }

  // ---- Variant endpoints ----
  variantsForProduct(term: string): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/api/v1/admin/variants?product=' + term
    );
  }

  findOneVariant(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/variant/' + id);
  }

  createVariant(data: Variant): Observable<any> {
    delete data['createdAt'];
    delete data['updatedAt'];

    return this.http.post(environment.apiUrl + '/api/v1/admin/variant', data);
  }

  createVariants(variants: Array<Variant>): Observable<any> {
    const data = variants.map((v) => {
      delete v.createdAt;
      delete v.updatedAt;
      delete v.toCreate;

      return v;
    });

    return this.http.post(environment.apiUrl + '/api/v1/admin/variants', data);
  }

  updateVariant(variant: Variant): Observable<any> {
    delete variant['createdAt'];
    delete variant['updatedAt'];

    return this.http.put(environment.apiUrl + '/api/v1/admin/variant', variant);
  }

  deleteVariant(id: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/api/v1/admin/variant/' + id);
  }

  setMasterVariant(sku: string, productId: string): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/v1/admin/variant/master', {
      sku: sku,
      productId: productId,
    });
  }

  // ---- Category endpoints ----
  getCategoryTree() {
    return this.http.get(
      environment.apiUrl + '/api/v1/category-tree?ctype=default&status=all'
    );
  }

  getCollections(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/api/v1/admin/product/collections'
    );
  }

  getCategorization(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/api/v1/admin/product/categorization'
    );
  }

  getTagsAndCategories(): Observable<TagsAndCategories> {
    return this.http.get<TagsAndCategories>(`${environment.apiUrl}/api/v1/tags-and-categories`);
  }

  // --- Custom Fields ----
  getAvailableCustomFields(): Observable<ProductCustomField[]> {
    return this.http.get<ProductCustomField[]>(`${environment.apiUrl}/api/v1/admin/variants/available-custom-fields`);
  }

  getAvailableCustomFieldsForSKU(sku: string): Observable<ProductCustomField[]> {
    return this.http.get<ProductCustomField[]>(`${environment.apiUrl}/api/v1/admin/variant/${sku}/available-custom-fields`);
  }

  getCustomFieldsForSKU(sku: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/variant/${sku}/custom-fields`);
  }

  addCustomField(customField: ProductCustomField): Observable<ProductCustomField> {
    return this.http.post<ProductCustomField>(`${environment.apiUrl}/api/v1/admin/variants/custom-field`, customField);
  }

  patchCustomFields(sku: string, customFields: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/admin/variant/${sku}/custom-fields`, customFields);
  }

  uploadFile(file): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}/api/v1/admin/file`, data);
  }

  getCustomFieldsValues(category: string = '', subcategory: string = '', term: string = '', customFields: string[]): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        customFields,
        collection: category || '',
        subtype: subcategory || '',
        term: term || '',
      }
    });
    return this.http.get(`${environment.apiUrl}/api/v1/store/custom-fields-values`, {
      params: qParams,
    });
  }

  // ---- Stock Endpoints ----
  getStock(
    warehouse: string,
    q: string,
    page: number = 0,
    size: number = 100
  ): Observable<any> {
    const qParams = new HttpParams({
      fromObject: {
        q: String(q),
        page: String(page),
        size: String(size),
      },
    });
    return this.http.get(`${environment.apiUrl}/api/v1/stock/${warehouse}`, {
      params: qParams,
    });
  }

  setStock(account: string, sku: string, qty: number): Observable<any> {
    return this.http.post(
      environment.apiUrl + `/api/v1/stock/update/${account}/${sku}/${qty}`,
      {}
    );
  }

  getWarehouses(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/warehouses`);
  }

  initializeMissingSkuRecords(wh: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/v1/stock/initialize/${wh}`, {});
  }

  // ---- Embedded Products Endpoints ----
  getEmbeddedProductsLayout(name: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/v1/admin/embeddable-layout/${name}`
    );
  }

  addEmbeddedProductsLayout(embedded, createJWT = false): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/admin/embeddable-layout?createJWT=${String(
        createJWT
      )}`,
      embedded
    );
  }

  // ---- Price List Endpoints ----
  getPricesList(): Observable<PriceList[]> {
    return this.http.get<PriceList[]>(
      `${environment.apiUrl}/api/v1/admin/prices-list`
    );
  }

  getPriceList(id: string): Observable<PriceList> {
    return this.http.get<PriceList>(
      `${environment.apiUrl}/api/v1/admin/prices-list/${id}`
    );
  }

  getProductsInPriceList(id: string): Observable<ProductPriceList[]> {
    return this.http.get<ProductPriceList[]>(
      `${environment.apiUrl}/api/v1/admin/prices-list/${id}/products`
    );
  }

  getProductsNotInPriceList(id: string): Observable<ProductPriceList[]> {
    return this.http.get<ProductPriceList[]>(
      `${environment.apiUrl}/api/v1/admin/prices-list/${id}/available-products`
    );
  }

  addProductToPriceList(
    listId: string,
    products: ProductPriceList[]
  ): Observable<ProductPriceList> {
    return this.http.post<ProductPriceList>(
      `${environment.apiUrl}/api/v1/admin/prices-list/${listId}/products`,
      products
    );
  }

  patchList(listId: string, props): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/prices-list/${listId}`,
      props
    );
  }

  patchProductInPriceList(
    listId: string,
    sku: string,
    productProps
  ): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/api/v1/admin/prices-list/${listId}/products/${sku}`,
      productProps
    );
  }

  createPriceList(data: PriceList): Observable<PriceList> {
    return this.http.post<PriceList>(
      `${environment.apiUrl}/api/v1/admin/prices-list`,
      data
    );
  }

  // ------ Logistics ------
  getLogisticsSettings(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/admin/logistics/settings');
  }

  // ---- Customer Endpoints ----
  getCustomers(
    type: 'consumer' | 'business' = 'consumer',
    q: string = '',
    priceListId: string = '',
    page: number = 0,
    rowsPerPage: number = 100
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        type,
        q,
        priceListId,
        page: String(page),
        size: String(rowsPerPage),
      },
    });
    return this.http.get(`${environment.apiUrl}/api/v2/customers`, { params });
  }

  patchCustomer(id: string, props): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/api/v1/customers/${id}`, {
      ...props,
      customerId: id,
    });
  }

  // --
  // Price list V2
  // --
  getPriceListV2(): Observable<PriceListV2[]> {
    return this.http.get<PriceListV2[]>(`${environment.apiUrl}/api/v2/admin/price-list`);
  }
  createPriceListV2(priceList: PriceListV2): Observable<PriceListV2> {
    return this.http.post<PriceListV2>(`${environment.apiUrl}/api/v2/admin/price-list`, priceList);
  }
  getPricesV2(): Observable<PriceListV2Price[]> {
    return this.http.get<PriceListV2Price[]>(`${environment.apiUrl}/api/v2/admin/price-list/prices`);
  }
  syncPricesWithCatalog(): Observable<PriceListV2Price[]> {
    return this.http.get<PriceListV2Price[]>(`${environment.apiUrl}/api/v2/admin/price-list/prices/sync`);
  }
  assignMissingPrices(listCode: string): Observable<PriceListV2Price[]> {
    return this.http.put<PriceListV2Price[]>(`${environment.apiUrl}/api/v2/admin/price-list/${listCode}/assign-missing-skus`, null);
  }
  setListPrice(listCode: string, listPrices: PriceListV2PriceInput[]): Observable<PriceListV2Price[]> {
    return this.http.put<PriceListV2Price[]>(`${environment.apiUrl}/api/v2/admin/price-list/${listCode}/prices`, listPrices);
  }

  setBulkPrices(csv, onlyPreview: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('csv', csv);
    const params = new HttpParams({
      fromObject: {
        onlyPreview: String(onlyPreview),
      }
    });

    return this.http.patch(environment.apiUrl + '/api/v1/admin/product/bulk-prices', formData, { params });
  }

  // ---- External Endpoints ----
  bitlyShorten(url: string): Observable<any> {
    const encodedURL = encodeURIComponent(url);
    return this.http.jsonp(
      `${environment.bitly.api}${environment.bitly.shortenURI}?` +
        `access_token=${environment.bitly.accessToken}&longUrl=${encodedURL}`,
      'callback'
    );
  }

  getProductPriceValidityInOrderPolicy(): Observable<ProductPriceValidityInOrderPolicy> {
    return this.http.get<ProductPriceValidityInOrderPolicy>(`${environment.apiUrl}/api/v1/account/policy/product_price_validity_in_order`);
  }

  setProductPriceValidityInOrderPolicy(policy: ProductPriceValidityInOrderPolicy): Observable<ProductPriceValidityInOrderPolicy> {
    return this.http.put<ProductPriceValidityInOrderPolicy>(
      `${environment.apiUrl}/api/v1/account/policy/product_price_validity_in_order`,
      policy
    );
  }

  flatCategories(rootCats) {
    const stack = rootCats,
      array = [];

    while (stack.length > 0) {
      const node = stack.pop();

      if (!node.children || !node.children.length) {
        // No childs, add leaf
        array.push({ name: node.name, slug: node.slug });
      } else {
        array.push({ name: node.name, slug: node.slug });
        for (let i = 0; i < node.children.length; ++i) {
          stack.push(node.children[i]);
        }
      }
    }

    return array;
  }
}
