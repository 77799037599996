import { Injectable, OnDestroy } from '@angular/core';
import { AccountService, AuthService } from '../auth';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
  hiddenIfFeature?: string;
  visibleForFeature?: string;
  visibleForSchemas?: string[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  role?: string;
  hiddenIfFeature?: string;
  visibleForFeature?: string;
  visibleForSchemas?: string[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENU_ITEMS: Menu[] = [
  {
    label: 'Panel de Navegación',
    main: [
      {
        state: 'inicio',
        name: 'Inicio',
        type: 'link',
        icon: 'ti-home',
      },
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-dashboard',
      },
      {
        state: 'carritos',
        name: 'Seguimiento a Carritos',
        type: 'sub',
        icon: 'ti-shopping-cart-full',
        // feature: 'cart_management',
        children: [
          {
            state: 'lista',
            name: 'Listado',
          },
          // {
          //   state: 'configuracion',
          //   name: 'Configuración',
          // },
          {
            state: 'configuracion',
            name: 'Configuración',
            // badge: [
            //   {
            //     type: 'danger',
            //     value: 'Beta',
            //   },
            // ],
          },
          {
            state: 'funnel',
            name: 'KPI\'s funnel de la venta',
          }
        ],
      },
      {
        state: 'pedidos',
        name: 'Pedidos',
        type: 'sub',
        icon: 'ti-layout-list-thumb',
        children: [
          {
            state: '',
            name: 'Seguimiento a Pedidos',
          },
          {
            state: 'table',
            name: 'Seguimiento a Pedidos (tabla)',
          },
          {
            state: 'status-list',
            name: 'Status List',
          },
          {
            state: 'guides-list',
            name: 'Listado de guías',
          },
        ],
      },
      // {
      //     state: 'cobros',
      //     name: 'Cobros',
      //     type: 'sub',
      //     icon: 'ti-credit-card',
      //     children: [
      //         {
      //             state: '',
      //             name: 'Todos'
      //         },
      //         {
      //             state: 'nuevo',
      //             name: 'Nuevo'
      //         }
      //     ]
      // },
      {
        state: 'reportes',
        name: 'Reportes',
        type: 'sub',
        icon: 'ti-receipt',
        children: [
          {
            state: 'ventas-y-costos',
            name: 'Ventas y Costos',
          },
          {
            state: 'estadisticas',
            name: 'Estadísticas de Venta',
          },
          {
            state: 'kpis-operativos',
            name: 'KPIs Operativos',
          },
          {
            state: 'ventas-sku',
            name: 'Ventas por SKU',
          },
          // {
          //   state: 'funnel',
          //   name: 'Funnel de compra',
          // },
          // {
          //   state: 'general-analytics',
          //   name: 'Analíticas generales',
          // },
          // {
          //   state: 'indicadores',
          //   name: 'Estadísticas landing',
          // },
          {
            state: 'integration-transactions',
            name: 'Transacciones de integración',
          },
        ],
      },
      {
        state: 'productos',
        name: 'Productos',
        type: 'sub',
        icon: 'ti-bag',
        children: [
          {
            state: '',
            name: 'Todos',
          },
          {
            state: 'nuevo',
            name: 'Agregar',
          },
          {
            state: 'inventario',
            name: 'Inventario',
          },
          {
            state: 'enlaces',
            name: 'Enlaces de Venta',
          },
          {
            state: 'listas-precios',
            name: 'Listas de Precios',
          },
          {
            state: 'listas-precios-zonas',
            name: 'Precios por Zona',
          },
          {
            state: 'politica-precios-pedidos',
            name: 'Política de precios en pedidos',
            visibleForSchemas: ['prissa', 'construramablanquita'],
          },
          {
            state: 'bundles',
            name: 'Bundles',
          },
          {
            state: 'campos-personalizados',
            name: 'Campos Personalizados',
          },
          {
            state: 'carga-masiva-precios',
            name: 'Carga masiva de precios',
          },
        ],
      },
      {
        state: 'clientes',
        name: 'Clientes',
        type: 'sub',
        icon: 'ti-id-badge',
        children: [
          {
            state: 'consumidores',
            name: 'Consumidores',
          },
          {
            state: 'negocios',
            name: 'Negocios',
          },
          {
            state: 'usuarios',
            name: 'Usuarios registrados'
          },
          {
            state: 'clients-report',
            name: 'Reporte de clientes'
          },
          {
            state: 'campos-personalizados',
            name: 'Campos personalizados'
          }
        ],
      },
      {
        state: 'lealtad',
        name: 'Lealtad',
        type: 'sub',
        icon: 'ti-star',
        children: [
          {
            state: 'cupones',
            name: 'Cupones',
          },
          {
            state: 'recomendaciones',
            name: 'Recomendaciones',
          },
          {
            state: 'bonificaciones',
            name: 'Bonificaciones',
          },
          {
            state: 'descuentos-especiales',
            name: 'Descuentos Especiales',
          },
          {
            state: 'reporte',
            name: 'Reporte',
          },
          {
            state: 'reporte-semanal',
            name: 'Estadísticas',
          },
        ],
      },
      {
        state: 'logistica',
        name: 'Logística',
        type: 'sub',
        icon: 'ti-truck',
        children: [
          {
            state: 'guias',
            name: 'Gestión de Guías',
          },
          {
            state: 'almacenes',
            name: 'Almacenes',
            visibleForFeature: 'logistics_geocode',
          },
          {
            state: 'metodos',
            name: 'Expectativa de Entrega',
            visibleForFeature: 'logistics_geocode',
          },
          {
            state: 'reglas',
            name: 'Reglas de Productos',
            visibleForFeature: 'logistics_geocode',
          },
          {
            state: 'zonas-regiones',
            name: 'Zonas y Regiones',
            visibleForFeature: 'logistics_geocode',
          },
          // {
          //     state: 'recoger-en-tienda',
          //     name: 'Recoger en tienda'
          // },
          {
            state: 'configuracion',
            name: 'Configuración',
          },
          {
            state: 'zonas-tarifas',
            name: 'Zonas y Tarifas',
            hiddenIfFeature: 'logistics_geocode',
          },
        ],
      },
      {
        state: 'capacidad-instalada',
        name: 'Capacidad instalada',
        type: 'sub',
        icon: 'ti-list-ol',
        children: [
          {
            state: 'ubicaciones',
            name: 'Ubicaciones',
          },
          {
            state: 'capacidad',
            name: 'Capacidad instalada',
            // visibleForFeature: 'logistics_geocode',
          },
          {
            state: 'eventos',
            name: 'Eventos',
          },
        ],
      },
      {
        state: 'campaign',
        name: 'Campañas',
        type: 'sub',
        icon: 'ti-layout-list-large-image',
        children: [
          {
            state: 'lista',
            name: 'Lista de Campañas',
          },
          {
            state: 'revision',
            name: 'Revisión de Campañas',
          },
          {
            state: 'revision-productos',
            name: 'Revisión de Productos',
          },
          {
            state: 'revision-vendedores',
            name: 'Revisión de Vendedores',
          },
          {
            state: 'city-managers',
            name: 'Asignación de City Managers',
          },
        ],
      },
      {
        state: 'supply-chain',
        name: 'Supply Chain',
        type: 'link',
        icon: 'ti-package',
        visibleForFeature: 'supply_chain',
      },
      {
        state: 'integraciones',
        name: 'Integraciones',
        type: 'link',
        icon: 'ti-sharethis',
      },
      {
        state: 'webintegrations',
        name: 'Servicios de Integración',
        type: 'link',
        icon: 'ti-sharethis',
      },
      {
        state: 'widget',
        name: 'Configurar Carrito',
        type: 'link',
        icon: 'ti-shopping-cart',
      },
      {
        state: 'sitio',
        name: 'Sitio Web',
        type: 'sub',
        icon: 'ti-world',
        children: [
          {
            state: 'website-builder',
            name: 'Diseñador',
            type: 'link',
            visibleForFeature: 'website',
          },
          {
            state: 'temas',
            name: 'Sitio Web',
            type: 'link',
          },
        ],
      },
      {
        state: 'content-manager',
        name: 'Gestión de Contenidos',
        type: 'link',
        icon: 'ti-layout',
      },
      {
        state: 'suscripciones',
        name: 'Suscripciones',
        type: 'sub',
        icon: 'ti-credit-card',
        children: [
          {
            state: 'administracion',
            name: 'Planes',
          },
          {
            state: 'miembros',
            name: 'Miembros Vigentes',
          },
          {
            state: 'cupones',
            name: 'Cupones',
          },
        ],
      },
      {
        state: 'marketplace',
        name: 'Marketplace',
        type: 'sub',
        icon: 'ti-shopping-cart-full',
        visibleForSchemas: ['dyd_sandbox', 'dando_y_dando', 'reto_front'],
        children: [
          {
            state: 'pendientes',
            name: 'Productos pendientes',
          },
          {
            state: 'publicados',
            name: 'Productos publicados',
          },
          {
            state: 'producto',
            name: 'Nuevo Producto',
          },
          {
            state: 'vendedores',
            name: 'Vendedores',
          },
          {
            state: 'fundaciones',
            name: 'Fundaciones',
          },
          {
            state: 'ventas-sku',
            name: 'Ventas por SKU',
          },
          // {
          //   state: 'ventas-y-costos',
          //   name: 'Ventas y Costos'
          // },
          {
            state: 'configuracion',
            name: 'Configuración',
          },
        ],
      },
      {
        state: 'cuenta',
        name: 'Mi Cuenta',
        type: 'sub',
        icon: 'ti-settings',
        children: [
          {
            state: 'informacion',
            name: 'Información',
          },
          {
            state: 'accesos',
            name: 'Accesos y Notificaciones',
          },
          {
            state: 'supply-chain',
            name: 'Supply Chain',
            visibleForFeature: 'supply_chain',
          },
          {
            state: 'cobros',
            name: 'Procesadores de Cobros',
          },
          {
            state: 'planes-pagos',
            name: 'Plan y Pagos',
          },
          {
            state: 'terminos',
            name: 'Declaraciones Legales',
          },
          {
            state: 'emails-transaccionales',
            name: 'Correos Transaccionales',
          },
        ],
      },
    ],
  },
];

@Injectable()
export class MenuItems implements OnDestroy {
  tokenChangeObserver;
  roles: Array<String>;
  features: Array<String>;
  restrictions: Array<String>;
  panelRestricted = false;
  schema = '';

  constructor(
    private authService: AuthService,
    private accountService: AccountService
  ) {
    const sRoles = this.authService.tokenDetails().roles || '';
    this.roles = (sRoles.split(',') || []).map((r) =>
      r.replace('ROLE_', '').trim().toUpperCase()
    );
    this.features = [];
    this.tokenChangeObserver = this.accountService.tokenChange.subscribe(() =>
      this.tokenChange()
    );
    this.tokenChange();
  }

  ngOnDestroy() {
    this.tokenChangeObserver.unsubscribe();
  }

  tokenChange() {
    if (!this.accountService.getToken()) {
      this.features = [];
      this.restrictions = [];
      return;
    }
    const tokenDetails = this.accountService.tokenDetails();
    this.schema = tokenDetails.aud || '';

    const sFeats = tokenDetails.scope || '';
    this.features = (sFeats.split(' ') || [])
      .map((f) => f.trim())
      .filter((f) => typeof f === 'string' && f.length > 0)
      .map((f) => f.split(':')[0]);

    const sRestrictions = tokenDetails.restrictions || '';
    this.restrictions = (sRestrictions.split(' ') || [])
      .map((f) => f.trim())
      .filter((f) => typeof f === 'string' && f.length > 0);
    this.panelRestricted = this.restrictions.some((r) => r === 'panel');
  }

  getAll(): Menu[] {
    return MENU_ITEMS.map((mi) => {
      mi.main = mi.main
        .filter((i) => !this.panelRestricted || i.state === 'cuenta')
        .filter(
          (i) =>
            !i['role'] ||
            this.roles.indexOf(i['role'].trim().toUpperCase()) > -1
        )
        .filter(
          (i) =>
            !i['hiddenIfFeature'] ||
            this.features.indexOf(i['hiddenIfFeature'].trim()) === -1
        )
        .filter(
          (i) =>
            !i['visibleForFeature'] ||
            this.features.indexOf(i['visibleForFeature'].trim()) > -1
        )
        .filter(
          (i) =>
            !i['visibleForSchemas'] ||
            i['visibleForSchemas'].indexOf(this.schema) > -1
        )
        .map((m) => {
          if (m.children !== undefined && Array.isArray(m.children)) {
            m.children = m.children
              .filter(
                (c) =>
                  !c['hiddenIfFeature'] ||
                  this.features.indexOf(c['hiddenIfFeature'].trim()) === -1
              )
              .filter(
                (c) =>
                  !c['visibleForFeature'] ||
                  this.features.indexOf(c['visibleForFeature'].trim()) > -1
              )
              .filter(
                (i) =>
                  !i['visibleForSchemas'] ||
                  i['visibleForSchemas'].indexOf(this.schema) > -1
              );
          }
          return m;
        });
      return mi;
    });
  }
}
