import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import swal from 'sweetalert2';
import { DuePaymentState } from './due-payment.state';

@Injectable()
export class DuePaymentService {

  resolveAction;

  constructor(private modalService: NgbModal, private state: DuePaymentState, private http: HttpClient) { }

  show(dueAndCycle = null): Promise<any> {
    if (dueAndCycle !== null) {
      this.state.setDueAndCycle(dueAndCycle);
    }

    console.log(this.state.template);
    this.state.modal = this.modalService.open(this.state.template, {backdrop: 'static'});
    return this.state.modal.result;
  }

  showAlert() {
    if (this.state.getDueAndCycle() === null) {
      return;
    }
    swal({
      type: 'warning',
      title: 'Tienes un Adeudo',
      html: this.state.getMessage(),
      confirmButtonText: 'Realizar pago',
    }).then((res) => {
      if (res.value) {
        this.show();
      }
    });
  }

  duePayment(paymentDetails): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/billing/due-payment`, paymentDetails);
  }
}
