import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { CleanLayoutComponent } from './layouts/clean/clean-layout.component';
import { OrderPrintComponent } from './orders/print/print.component';
import { PaymentsListComponent } from './payments/list/list.component';
import { PaymentsMakeComponent } from './payments/make/make.component';
import { AuthGuard, FeaturesGuard } from './shared/auth';
import { ThemesComponent } from './themes/themes.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'inicio',
        pathMatch: 'full',
      },
      {
        path: 'inicio',
        component: HomeComponent,
        data: {
          breadcrumb: 'Inicio Rápido',
          hideBreadcrumb: true,
        },
      },
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'carritos',
        loadChildren: './carts/carts.module#CartsModule',
      },
      {
        path: 'pedidos',
        loadChildren: './orders/orders.module#OrdersModule',
      },
      {
        path: 'cobros/nuevo',
        component: PaymentsMakeComponent,
        data: {
          breadcrumb: 'Realizar Cobro',
        },
      },
      {
        path: 'cobros/',
        redirectTo: 'cobros',
        pathMatch: 'full',
      },
      {
        path: 'cobros',
        component: PaymentsListComponent,
        data: {
          breadcrumb: 'Lista de Cobros',
        },
      },
      {
        path: 'reportes',
        loadChildren: './reports/reports.module#ReportsModule',
      },
      {
        path: 'clientes',
        loadChildren: './customers/customers.module#CustomersModule',
      },
      {
        path: 'lealtad',
        loadChildren: './loyalty/loyalty.module#LoyaltyModule',
      },
      {
        path: 'integraciones',
        loadChildren: './integrations/integrations.module#IntegrationsModule',
      },
      {
        path: 'cuenta',
        loadChildren: './account/account.module#AccountModule',
      },
      {
        path: 'productos',
        loadChildren: './products/products.module#ProductsModule',
      },
      {
        path: 'widget',
        loadChildren: './widget/widget.module#WidgetModule',
      },
      {
        path: 'webintegrations',
        loadChildren:
          './webintegrations/webintegrations.module#WebintegrationsModule',
      },
      {
        path: 'content-manager',
        loadChildren:
          './content-manager/content-manager.module#ContentManagerModule',
      },
      {
        path: 'suscripciones',
        loadChildren:
          './subscriptions/subscriptions.module#SubscriptionsModule',
      },
      {
        path: 'marketplace',
        loadChildren: './marketplace/marketplace.module#MarketplaceModule',
      },
      {
        path: 'sitio/temas',
        component: ThemesComponent,
        canActivate: [FeaturesGuard],
        data: {
          breadcrumb: 'Temas',
          require: ['website'],
        },
      },
      {
        path: 'logistica',
        loadChildren: './logistics/logistics.module#LogisticsModule',
      },
      {
        path: 'capacidad-instalada',
        loadChildren:
          './installed-capacity/installed-capacity.module#InstalledCapacityModule',
      },
      {
        path: 'campaign',
        loadChildren: './campaigns/campaigns.module#CampaignsModule',
      },
      {
        path: 'supply-chain',
        loadChildren: './supply-chain/supply-chain.module#SupplyChainModule',
      },
    ],
  },
  {
    path: '',
    component: CleanLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
      },
      {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule',
      },
      {
        path: 'landing',
        loadChildren: './landing/landing.module#LandingModule',
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren:
          './maintenance/offline-ui/offline-ui.module#OfflineUiModule',
      },
      {
        path: 'tema',
        canActivate: [AuthGuard],
        loadChildren: './theme-editor/theme-editor.module#ThemeEditorModule',
      },
      {
        path: 'print/pedido/:id',
        component: OrderPrintComponent,
      },
      {
        path: 'sitio/website-builder',
        canActivate: [FeaturesGuard],
        loadChildren: './website/website.module#WebsiteModule',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
