import { Injectable } from '@angular/core';

@Injectable()
export class ExternalDependenciesService {

  loadedScripts = [];
  loadedStyles = [];

  constructor() { }

  loadScripts(externalScripts: any[]): Promise<any> {
    const promises = externalScripts
      .filter((s) => !this.loadedScripts.includes(s.url))
      .map((s) => {
        return new Promise((resolve, reject) => {
          this.loadScript(s.url)
            .then(() => {
              this.loadedScripts.push(s.url);
              s.afterLoad();
              resolve(s.url);
            })
            .catch((err) => {
              console.error('Can not load: ', s.url, err);
              resolve(s.url);
            });
        });
      });

    if (promises.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(promises);
  }

  loadStyles(externalStylesheets: any[]): Promise<any> {
    const promises = externalStylesheets
      .filter((s) => !this.loadedStyles.includes(s.url))
      .map((s) => {
        return new Promise((resolve, reject) => {
          this.loadStyle(s.url)
            .then(() => {
              this.loadedStyles.push(s.url);
              s.afterLoad();
              resolve(s.url);
            })
            .catch((err) => {
              console.error('Can not load: ', s.url, err);
              resolve(s.url);
            });
        });
      });

    if (promises.length === 0) {
      return Promise.resolve();
    }

    return Promise.all(promises);
  }

  loadScript(scriptUrl): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      scriptElement.onerror = reject;
      document.head.appendChild(scriptElement);
    });
  }

  loadStyle(styleUrl) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.rel = 'stylesheet';
      styleElement.href = styleUrl;
      styleElement.onload = resolve;
      styleElement.onerror = reject;
      document.head.appendChild(styleElement);
    });
  }
}
