import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'mvta-datatable-edit-cell',
    templateUrl: 'edit-cell.component.html'
})
export class DatatableEditCellComponent implements OnInit, OnChanges {

    @Input()
    model;

    @Input()
    value;

    @Input()
    disabled;

    @Output()
    update = new EventEmitter();

    name;
    status = {
        editing: false,
        saving: false,
        oldValue: null,
        focus: false
    };

    constructor() {
        this.name = 'editableCell-' + Math.round(new Date().getTime() / 1000);
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.hasOwnProperty('model') || changes.hasOwnProperty('value')) && this.status.saving) {
            this.status.saving = false;
            this.status.editing = false;
        }

        if (changes.hasOwnProperty('disabled') && changes.disabled.currentValue && !this.status.saving) {
            this.status.editing = false;
        }
    }

    onEdit() {
        if (this.disabled) {
            return;
        }
        this.status.editing = true;
        if (Array.isArray(this.model)) {
            this.status.oldValue = [...this.model];
        } else if (typeof this.model === 'object') {
            this.status.oldValue = Object.assign({}, this.model);
        } else {
            this.status.oldValue = this.model;
        }
        this.status.focus = true;
    }

    onCancelEdit() {
        if (this.disabled) {
            return;
        }
        this.status.editing = false;
        if (Array.isArray(this.model)) {
            this.model = [...this.status.oldValue];
        } else if (typeof this.model === 'object') {
            this.model = Object.assign({}, this.status.oldValue);
        } else {
            this.model = this.status.oldValue;
        }
    }

    onUpdate(ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        if (this.status.saving || this.disabled) {
            return;
        }
        if (!Object.is(this.model, this.status.oldValue)) {
            this.status.saving = true;
            this.update.emit(this.model);
            return;
        }
        this.status.editing = false;
    }
}
