import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, skip, tap } from 'rxjs/operators';

@Component({
  selector: 'mvta-color-picker',
  templateUrl: 'color-picker.component.html'
})

export class ColorPickerDebounceComponent implements OnInit, OnDestroy {
  @HostBinding('class.input-color-picker') private colorPickerClass = true;
  @HostBinding('class.d-block') private blockClass = true;

  @Input() color: string;
  @Input() debounce = 300;
  @Input() position: 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'right';
  @Output() colorChange = new EventEmitter<string>();

  colorCache$ = new BehaviorSubject<string>(null);
  sub = new Subscription();

  constructor() { }

  ngOnInit() {
    this.colorCache$.next(this.color || '#000');

    const colorChangeEmit$ = this.colorCache$.pipe(
      debounceTime(this.debounce),
      skip(1),
      tap(color => this.colorChange.emit(color))
    );
    this.sub.add(colorChangeEmit$.subscribe());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
